const columns = {
  id: { value: "" },
  synchronized: { value: "" },
  service: { value: "Ресурс", sortable: true },
  login: { value: "Учётная запись", sortable: true },
  fio: { value: "Пользователь", sortable: true },
  createdAt: { value: "Дата создания", sortable: true },
  lastLoginAt: { value: "Последний вход", sortable: true },
  updateAccountDate: { value: "Обновление", sortable: true },
  status: { value: "Статус", sortable: true },
};

export default columns;
