<template>
  <SearchInput placeholder="Начните вводить…" />
  <Accordion :multiple="true" class="accordion">
    <AccordionTab v-for="role in roles" :key="role.id">
      <template #header>
        <div class="accordion-header">
          <Checkbox
            :id="role.id"
            @click.stop
            :checked="selectedItems.includes(role.id)"
            @update:modelValue="toggleSelection(role.id)"
          />
          <div class="accordion-text">
            <h3>{{ role.name }}</h3>
            <p>{{ role.description }}</p>
          </div>
        </div>
      </template>
      <div class="accordion-content">
        <h4>Список прав доступа</h4>
        <p v-for="item in role.items" :key="item.id">{{ item.title }}</p>
      </div>
    </AccordionTab>
  </Accordion>
</template>

<script setup>
import SearchInput from "@/components/table/SearchInput.vue";
import Checkbox from "@/components/ui/Checkbox/Checkbox.vue";
import Accordion from "primevue/accordion";
import AccordionTab from "primevue/accordiontab";
import { ref, watch } from "vue";

const props = defineProps({
  modelValue: {
    type: Array,
    default: () => [],
  },
  roles: Array,
});

const selectedItems = ref([...props.modelValue]);

watch(
  selectedItems,
  (newValue) => {
    emit("update:modelValue", newValue);
  },
  { deep: true }
);

// Эмиттер для обновлений
const emit = defineEmits(["update:modelValue"]);

const toggleSelection = (id) => {
  if (selectedItems.value.includes(id)) {
    selectedItems.value = selectedItems.value.filter((item) => item !== id);
  } else {
    selectedItems.value.push(id);
  }
};
</script>

<style scoped lang="scss">
@import "@/assets/scss/_vars";
.accordion {
  margin-top: 32px;

  &-header {
    display: flex;
    gap: 20px;
  }

  &-text {
    h3 {
      font-size: 18px;
      line-height: 28px;
    }
    p {
      font-size: 12px;
      line-height: 20px;
      font-weight: 400;
      color: #8f97b1;
    }
  }

  &-content {
    margin-left: 40px;
    > * {
      margin-bottom: 16px;
    }
    h4 {
      font-size: 16px;
      font-weight: 700;
      line-height: 20px;
      color: $theme900;
    }

    p {
      font-size: 14px;
      line-height: 20px;
    }
  }
}
</style>
