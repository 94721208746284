<template>
  <div class="date">
    <label for="icondisplay" class="date-label" v-if="label">
      {{ label }}
    </label>
    <Calendar
      class="date-picker"
      v-model="selectedDate"
      @update:model-value="$emit('update:modelValue', selectedDate)"
      dateFormat="dd.mm.yy"
      :showOnFocus="false"
      showIcon
      iconDisplay="input"
      :baseZIndex="9999"
      v-bind="$attrs"
    />
  </div>
  <div class="date-error" v-if="errorCondition">{{ errorText }}</div>
</template>

<script setup>
import Calendar from "primevue/calendar";
import { ref, watch } from "vue";

const props = defineProps({
  modelValue: Date,
  label: {
    type: String,
    default: null,
  },
  errorText: {
    type: String,
    default: "Поле обязательно к заполнению",
  },
  errorCondition: {
    type: Boolean,
    default: false,
  },
});

const selectedDate = ref(props.modelValue);

watch(
  () => props.modelValue,
  (newValue) => {
    selectedDate.value = newValue;
  }
);
</script>

<style scoped lang="scss">
@import "@/assets/scss/_vars";

.date {
  position: relative;
  width: 100%;
}
.date-label {
  color: #3e445b;
  font-size: 12px;
  line-height: 16px;
  font-weight: 700;
}
.date-picker {
  display: flex;
  align-items: center;
  position: relative;
  color: #3e445b;
  margin-top: 8px;
}
.date-error {
  font-size: 12px;
  display: block;
  margin-top: 5px;
  color: #e21e4d;
}
</style>
