<template>
  <SideMenu @close="closeMenu" :title="title" width="804">
    <template #body>
      <div class="form">
        <div class="form-group">
          <div class="item">
            <FormInput
              v-model="formData.surname"
              type="text"
              label="Фамилия"
              size="small"
              placeholder="Введите фамилию"
              :error-condition="formErrors.surname"
            />
          </div>
          <div class="item">
            <FormInput
              v-model="formData.firstName"
              type="text"
              label="Имя"
              size="small"
              placeholder="Введите Имя"
              :error-condition="formErrors.firstName"
            />
          </div>
        </div>
        <div class="form-group-full">
          <div class="item">
            <FormInput
              v-model="formData.secondName"
              type="text"
              label="Отчество"
              size="small"
              placeholder="Введите Отчество"
              :error-condition="formErrors.secondName"
            />
          </div>
        </div>

        <div class="form-group">
          <div class="item">
            <FormInput
              v-model="formData.email"
              type="text"
              label="Email"
              size="small"
              placeholder="Введите Email"
              :error-condition="formErrors.email"
            />
          </div>
          <div class="item">
            <FormDate
              v-model="formData.birthDate"
              placeholder="Введите Дату рождения"
              label="Дата рождения"
              :error-condition="formErrors.birthDate"
            />
          </div>
        </div>

        <div class="item">
          <FormSelect
            v-model="formData.departmentId"
            :items="departments"
            label="Подразделение"
            placeholder="Выберите подразделение"
            :error-condition="formErrors.departmentId"
          />
        </div>

        <div class="item">
          <FormSelect
            v-model="formData.positionId"
            :items="positions"
            label="Должность"
            placeholder="Выберите должность"
            :error-condition="formErrors.positionId"
          />
        </div>
      </div>
    </template>
    <template #footer>
      <Button
        variant="solid"
        color="secondary"
        @click="closeMenu"
        :style="{
          width: '170px',
        }"
      >
        Отмена
      </Button>
      <Button
        @click="submitForm"
        :style="{
          width: '206px',
        }"
        >{{ submitText }}</Button
      >
    </template>
  </SideMenu>
</template>

<script setup>
import { ref, onMounted, watch } from "vue";
import { DepartmentsAPI } from "@/api/DepartmentsAPI";
import SideMenu from "@/components/ui/SideMenu/SideMenu.vue";
import Button from "@/components/ui/Button/Button.vue";

const props = defineProps({
  title: String,
  submitText: String,
  employee: {
    type: Object,
    default: () => ({}),
  },
});

const formData = ref({
  surname: props.employee?.surname || "",
  firstName: props.employee?.firstName || "",
  secondName: props.employee?.secondName || "",
  email: props.employee?.email || "",
  birthDate: props.employee?.birthDate || "",
  departmentId: props.employee?.departmentId || "",
  positionId: props.employee?.positionId || "",
});

const formErrors = ref({
  surname: false,
  firstName: false,
  secondName: false,
  email: false,
  birthDate: false,
  departmentId: false,
  positionId: false,
});

const departments = ref([]);
const positions = ref([]);

const emit = defineEmits(["submit", "close"]);

const validateForm = () => {
  for (let key of Object.keys(formData.value)) {
    if (formData.value[key] === "") {
      formErrors.value[key] = true;
    } else {
      formErrors.value[key] = false;
    }
  }

  if (Object.values(formErrors.value).every((value) => value === true)) {
    return false;
  }

  return true;
};

const submitForm = () => {
  if (!validateForm()) {
    return;
  }

  emit("submit", formData.value);
};

const closeMenu = () => {
  emit("close");
};

const getPositions = (id) => {
  const department = departments.value.find((dep) => dep.id === id);
  if (department.positions.length) {
    positions.value = department.positions;
  } else {
    positions.value = [];
    formData.value.positionId = "";
  }
};

watch(
  () => formData.value.departmentId,
  () => {
    getPositions(formData.value.departmentId);
  }
);

onMounted(() => {
  DepartmentsAPI.getAll().then((res) => {
    departments.value = res.data;

    if (formData.value.departmentId) {
      getPositions(formData.value.departmentId);
    }
  });
});
</script>
