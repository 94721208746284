<template>
  <Button
    v-if="!inactive"
    variant="outlined"
    color="default"
    icon="pi-times"
    @click="employeesStore.showFiredModal()"
    :style="{
      width: '127px',
    }"
    >Уволить</Button
  >
  <Button
    v-else
    icon="pi-history"
    @click="employeesStore.showFiredModal()"
    :style="{
      width: '186px',
    }"
    >Принять заново</Button
  >

  <FiredModal
    :isShowModal="employeesStore.isShowFiredModal"
    :mode="inactive ? 'hire' : 'fire'"
    @close="employeesStore.hideFiredModal()"
    @delete="!inactive ? fireEmployee() : hireEmployee()"
  />
</template>

<script setup>
import FiredModal from "@/components/modals/FiredModal.vue";
import Button from "@/components/ui/Button/Button.vue";
import { useEmployeesStore } from "../../model/store/useEmployeesStore";
import { useRoute } from "vue-router";
import { EmployeesAPI } from "@/api/EmployeesAPI";
import { notify } from "@kyvg/vue3-notification";
import notifications from "@/helpers/notifications";

defineProps({
  inactive: Boolean,
});

const route = useRoute();
const employeesStore = useEmployeesStore();

const fireEmployee = () => {
  EmployeesAPI.dismiss(route.params.id)
    .then(() => {
      notify({
        type: "success",
        text: "Пользователь уволен",
      });
      window.dispatchEvent(new CustomEvent("updateView"));
      employeesStore.hideFiredModal();
    })
    .catch((err) => {
      notifications(err);
    });
};

const hireEmployee = () => {
  EmployeesAPI.hire(route.params.id)
    .then(() => {
      notify({
        type: "success",
        text: "Пользователь принят",
      });
      window.dispatchEvent(new CustomEvent("updateView"));
      employeesStore.hideFiredModal();
    })
    .catch((err) => {
      notifications(err);
    });
};
</script>
