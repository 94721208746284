<template>
  <Loading :show="isLoading" />
  <EmployeeInfo :employee="employee" />
  <Tabs v-model="activeTab" :tabs="tabs" />
  <EmployeeForm
    v-if="employeesStore.isShowEditMenu && !isLoading"
    submitText="Сохранить"
    title="Редактировать данные пользователя"
    @submit="editEmployee"
    :employee="employee"
    @close="employeesStore.setEditMenu(false)"
  />
</template>

<script setup>
import EmployeeInfo from "./ui/pages/Info.vue";
import EmployeeRoles from "./ui/pages/Roles.vue";
import EmployeeAccounts from "./ui/pages/Accounts.vue";
import Tabs from "@/components/ui/Tabs/Tabs.vue";
import { onMounted, onUnmounted, ref } from "vue";
import { EmployeesAPI } from "@/api/EmployeesAPI";
import { useRoute } from "vue-router";
import { USER_STATUSES } from "@/enums/enum-status";
import { computed } from "vue";
import EmployeeForm from "./ui/components/EmployeeForm.vue";
import { notify } from "@kyvg/vue3-notification";
import notifications from "@/helpers/notifications";
import { useEmployeesStore } from "./model/store/useEmployeesStore";
import Loading from "@/components/Loading.vue";

const isLoading = ref(true);
const activeTab = ref(0);
const route = useRoute();
const employee = ref(null);
const employeesStore = useEmployeesStore();

const getEmployee = () => {
  EmployeesAPI.getById(route.params.id)
    .then((res) => {
      const response = res.data;
      const adapterResponse = {
        id: response.id,
        surname: response.surname,
        firstName: response.firstName,
        secondName: response.secondName,
        email: response.email,
        birthDate: response.birthDate,
        positionId: response.position.id,
        departmentId: response.department.id,
        positionName: response.positionName,
        departmentName: response.departmentName,
        inactive_status: response.inactive_status,
        activeRoles: response.externalRoles,
        processingRoles: response.requestExternalRoles.map((role) => ({
          ...role,
          status: USER_STATUSES[role.status],
        })),
        archiveRoles: response.historyExternalRoles,
        accounts: response.accounts,
      };
      employee.value = adapterResponse;

      isLoading.value = false;
    })
    .catch(() => {
      isLoading.value = false;
    });
};

const tabs = computed(() => {
  if (employee.value) {
    return [
      {
        title: "Роли пользователя",
        component: EmployeeRoles,
        props: {
          activeRoles: employee.value.activeRoles,
          processingRoles: employee.value.processingRoles,
          archiveRoles: employee.value.archiveRoles,
        },
      },
      {
        title: "Учетные записи",
        component: EmployeeAccounts,
        props: {
          accounts: employee.value.accounts,
        },
      },
    ];
  }
  return [
    {
      title: "Роли пользователя",
      component: EmployeeRoles,
      props: {
        activeRoles: [],
        processingRoles: [],
        archiveRoles: [],
      },
    },
    {
      title: "Учетные записи",
      component: EmployeeAccounts,
      props: {
        accounts: [],
      },
    },
  ];
});

const editEmployee = (payload) => {
  EmployeesAPI.update(payload, route.params.id)
    .then(() => {
      notify({
        type: "success",
        text: "Пользователь успешно изменен",
      });
      employeesStore.setEditMenu(false);
      window.dispatchEvent(new CustomEvent("updateView"));
    })
    .catch((err) => {
      notifications(err);
    });
};

onMounted(() => {
  getEmployee();
  window.addEventListener("updateView", getEmployee);
});

onUnmounted(() => {
  window.removeEventListener("updateView", getEmployee);
});
</script>
