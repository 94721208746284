<template>
  <div class="content">
    <Loading :show="loading" />
    <div class="table-header">
      <SearchInput
        placeholder="Поиск по ФИО пользователя, Подразделению или Должности..."
      />
      <div class="button-container">
        <GroupsList
          v-model:showGroups="isShowGroupsWindow"
          :groups="groupList"
          :all-items="employees"
          @update:groups="updateGroups"
          :selectedItems="selectedEmployees"
          @clickGroup="filterEmployeesByGroup"
          @changeGroup="updateGroup"
          @deleteGroup="deleteGroup"
        />
        <EmployeeFilters
          :employees="employees"
          :updateFilteredItems="setFilteredItems"
        />

        <Button
          size="sm"
          icon="pi-plus"
          @click="employeesStore.setAddModal(true)"
          :style="{
            width: '254px',
          }"
          >Добавить пользователя
        </Button>

        <EmployeeForm
          submitText="Добавить"
          title="Добавить пользователя"
          v-if="employeesStore.isShowAddModal"
          @close="employeesStore.setAddModal(false)"
          @submit="addEmployee"
        />
      </div>
    </div>
    <Table
      v-if="!currentGroup"
      v-model="selectedEmployees"
      name="employees"
      :columns="employeesColumns"
      :data="filteredItems"
      :isSelectable="true"
      :showCheckbox="true"
      @RowSelect="selectEmployeeHandler"
    >
      <template #dropdownActions="{ item }">
        <li @click="editEmployeeHandler(item.id)">Редактировать</li>
      </template>
    </Table>
    <GroupView
      v-else
      :group="currentGroup"
      :allItems="employees"
      :columns="employeesColumns"
      @close-group="currentGroup = null"
      @changeGroup="updateGroup"
      @deleteGroup="deleteGroup"
    >
      <template #tableActions="{ item }">
        <li @click="editEmployeeHandler(item.id)">Редактировать</li>
      </template>
    </GroupView>
  </div>
</template>

<script setup>
import { ref, computed, onMounted, onUnmounted } from "vue";
import { useRouter } from "vue-router";
import { EmployeesAPI } from "@/api/EmployeesAPI";
import Table from "@/components/table/Table";
import SearchInput from "@/components/table/SearchInput";
import Loading from "@/components/Loading";
import EmployeeFilters from "./ui/components/EmployeeFilters.vue";
import { employeesColumns } from "./model/consts/columns";
import GroupsList from "@/components/ui/Groups/GroupsList.vue";
import GroupView from "@/components/ui/Groups/GroupView.vue";
import Button from "@/components/ui/Button/Button.vue";
import EmployeeForm from "./ui/components/EmployeeForm.vue";
import { notify } from "@kyvg/vue3-notification";
import notifications from "@/helpers/notifications";
import { useEmployeesStore } from "./model/store/useEmployeesStore";

const employeesStore = useEmployeesStore();

const router = useRouter();
const loading = ref(false);
const employees = ref([]);

const isShowGroupsWindow = ref(false);
const groupList = ref([]);
const selectedEmployees = ref([]);
const currentGroup = ref(null);

const filterEmployeesByGroup = (group) => {
  currentGroup.value = group;
  isShowGroupsWindow.value = false;
};

const updateGroups = (newGroups) => {
  groupList.value = newGroups;
  selectedEmployees.value = [];
};

const updateGroup = (items, id) => {
  const group = groupList.value.find((group) => group.id === id);
  if (group) {
    group.items = items;
  }
  if (currentGroup.value) {
    currentGroup.value.items = items;
  }
};

const deleteGroup = (id) => {
  const index = groupList.value.findIndex((item) => item.id === id);
  if (index !== -1) {
    groupList.value.splice(index, 1);
  }
  if (currentGroup.value.id === id) {
    currentGroup.value = null;
  }
};

const selectEmployeeHandler = (event) => {
  router.push(`/employees/${event.data.id}`);
};

const editEmployeeHandler = (id) => {
  employeesStore.setEditMenu(true);
  router.push(`/employees/${id}`);
};

const addEmployee = (payload) => {
  EmployeesAPI.add(payload)
    .then(() => {
      notify({
        type: "success",
        text: "Пользователь успешно добавлен",
      });
      window.dispatchEvent(new CustomEvent("updateTable"));
      employeesStore.setAddModal(false);
    })
    .catch((err) => {
      notifications(err);
    });
};

const fetchData = () => {
  loading.value = true;
  EmployeesAPI.getAll()
    .then((res) => {
      employees.value = res.data;
    })
    .finally(() => {
      loading.value = false;
    });
};

onMounted(() => {
  fetchData();
  window.addEventListener("updateTable", fetchData);
});

const filteredItems = computed(() =>
  employees.value.map((employee) => ({ ...employee }))
);

const setFilteredItems = (items) => {
  filteredItems.value = items;
};

onUnmounted(() => {
  window.removeEventListener("updateTable", fetchData);
});
</script>
