<template>
  <Modal
    v-model:visible="showCreateGroup"
    title="Создать новую группу"
    subtitle="Введите имя  группы для создания нового списка"
    :onCancel="toggleCreateGroup"
    :onSave="addGroup"
  >
    <FormInput
      label="Имя группы"
      v-model="newGroupTitle"
      placeholder="Введите имя группы"
    />
  </Modal>

  <GroupMenu
    v-if="groupMode"
    :mode="groupMode"
    :group="currentGroup"
    :allItems="allItems"
    @close="groupMode = null"
    @updateGroup="updateGroupItems"
  />

  <Button
    class="toggle-button"
    variant="outlined"
    color="default"
    @click="onToggleGroups"
    :style="{
      width: '152px',
    }"
  >
    <img src="@/assets/images/icons/group.svg" alt="Group" />
    Группы
    <i class="pi pi-chevron-down" :class="{ open: showGroups }"></i>
  </Button>

  <GroupsModal :showGroups="showGroups">
    <div class="group-header">
      <h3>Список групп</h3>
      <p v-if="groups?.length === 0">Нет групп</p>
      <p v-else>{{ groups.length }} групп</p>
    </div>
    <div class="group-body">
      <div v-if="!groups || groups.length === 0" class="group-empty">
        <p>У вас еще нет созданных групп</p>
      </div>
      <div v-else class="group-list">
        <div
          class="group-item"
          v-for="group in groups"
          :key="group.id"
          @click="onGroupClick(group)"
        >
          <img src="@/assets/images/icons/group-view.svg" :alt="group.title" />
          <h4>{{ group.title }}</h4>

          <Dropdown>
            <template #trigger>
              <img src="@/assets/images/icons/grabber.svg" alt="Grabber" />
            </template>
            <template #content>
              <li @click="onGroupMenuHandler(group, 'exclude')">
                Исключить из группы
              </li>
              <li @click="onGroupMenuHandler(group, 'include')">
                Добавить в группу
              </li>
              <li @click="deleteGroupHandler(group.id)">Удалить группу</li>
            </template>
          </Dropdown>
        </div>
      </div>
    </div>
    <div class="group-footer">
      <Button @click="toggleCreateGroup"> Создать группу </Button>
    </div>
  </GroupsModal>
</template>

<script setup>
import Modal from "@/components/modals/Modal.vue";
import Button from "../Button/Button.vue";
import FormInput from "@/components/form/FormInput.vue";
import { ref } from "vue";
import Dropdown from "@/components/Dropdown.vue";
import GroupMenu from "./_GroupMenu.vue";
import GroupsModal from "./_GroupsModal.vue";

const props = defineProps({
  showGroups: Boolean, // Флаг отображения списка групп
  groups: Array, // Список существующих групп
  selectedItems: Array, // Список выбранных элементов
  allItems: Array, // Список всех элементов
});

const groupMode = ref(null);
const currentGroup = ref(null);

const emit = defineEmits([
  "update:showGroups",
  "update:groups",
  "clickGroup",
  "changeGroup",
  "deleteGroup",
]);

const showCreateGroup = ref(false); // Отображение модального окна создания группы
const newGroupTitle = ref(""); // Название новой группы

// Метод для переключения видимости списка групп
const onToggleGroups = () => {
  emit("update:showGroups", !props.showGroups); // Инверсия флага showGroups и передача нового значения через emit
};

// Метод для переключения отображения модального окна создания группы
const toggleCreateGroup = () => {
  showCreateGroup.value = !showCreateGroup.value; // Инверсия флага showCreateGroup
};

// Метод для добавления новой группы
const addGroup = () => {
  if (!newGroupTitle.value.trim()) return; // Проверка, что название группы не пустое

  const newGroup = {
    id: Date.now(), // Уникальный ID группы на основе текущего времени
    title: newGroupTitle.value, // Название группы
    items: [...props.selectedItems], // Копия выбранных элементов
  };

  const updatedGroups = [...props.groups, newGroup]; // Добавление новой группы в массив существующих групп
  emit("update:groups", updatedGroups); // Обновление массива групп в родительском компоненте

  newGroupTitle.value = ""; // Очистка поля ввода названия группы
  toggleCreateGroup(); // Закрытие модального окна создания группы
};

// Метод для обработки клика по группе
const onGroupClick = (group) => {
  emit("clickGroup", group); // Излучение события с переданным объектом группы
};

// Обработчик нажатия выпадающего меню в списке групп
const onGroupMenuHandler = (group, mode) => {
  currentGroup.value = group;
  groupMode.value = mode;
};

// Обработчик обновления группы (добавить/исключить)
const updateGroupItems = (updatedItems) => {
  emit("changeGroup", updatedItems, currentGroup.value.id);
  groupMode.value = null;
  currentGroup.value = null;
};

// Обработчик удаления группы
const deleteGroupHandler = (id) => {
  emit("deleteGroup", id);
};
</script>

<!-- Пример использования -->
<!-- <GroupsList
        v-model:showGroups="isShowGroupsWindow"
        :groups="groupList"
        :all-items="accounts"
        @update:groups="updateGroups"
        :selectedItems="selectedAccounts"
        @clickGroup="filterAccountsByGroup"
        @changeGroup="updateGroup"
        @deleteGroup="deleteGroup"
      />

      ----------------------------------

      const isShowGroupsWindow = ref(false);
      const groupList = ref([]);
      const selectedAccounts = ref([]);
      const accounts = ref([])

      const filterAccountsByGroup = (group) => {
        currentGroup.value = group;
        isShowGroupsWindow.value = false;
      };

      const updateGroups = (newGroups) => {
        groupList.value = newGroups;
        selectedAccounts.value = [];
      };

      const updateGroup = (items, id) => {
        const group = groupList.value.find((group) => group.id === id);
        if (group) {
          group.items = items;
        }
        if (currentGroup.value) {
          currentGroup.value.items = items;
        }
      };

      const deleteGroup = (id) => {
        const index = groupList.value.findIndex((item) => item.id === id);
        if (index !== -1) {
          groupList.value.splice(index, 1);
        }
        if (currentGroup.value.id === id) {
          currentGroup.value = null;
        }
      };
  -->

<style scoped lang="scss">
@import "@/assets/scss/_vars";
@import "@/assets/scss/_mixins";

.group-header {
  padding: 16px;
  border-bottom: 1px solid $theme100;

  h3 {
    font-size: 18px;
    line-height: 28px;
    color: $theme800;
  }

  p {
    font-size: 12px;
    line-height: 20px;
  }
}
.group-body {
  padding: 16px;
  height: 292px;
  overflow-y: auto;

  @include scrollbar();

  .group-empty {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.group-footer {
  box-shadow: -2px 0px 16px 0px rgba(0, 0, 0, 0.04);
  padding: 16px;

  button {
    width: 100%;
  }
}

.group-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 9px;
  padding: 16px;
  gap: 8px;
  cursor: pointer;
  transition: background-color 0.25s ease;

  &:hover {
    background-color: $theme100;
  }

  h4 {
    width: 100%;
  }
}

.toggle-button {
  .pi.open {
    transform: rotate(180deg);
    transition: transform 0.25s ease;
  }
}
</style>
