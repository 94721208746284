import axios from "axios";

const loginConfig = {
  baseURL: process.env.VUE_APP_BASE_URL,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
};

export const LoginAPIInstance = axios.create(loginConfig);

const defaultConfig = {
  baseURL: process.env.VUE_APP_BASE_URL,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
};

const token = localStorage.getItem("token");

if (token) defaultConfig.headers["authorization"] = `Bearer ${token}`;

export const DefaultAPIInstance = axios.create(defaultConfig);

DefaultAPIInstance.interceptors.response.use(
  function (config) {
    // Do something before request is sent
    return config;
  },
  function (error) {
    if (error.response.data.code === 901) {
      localStorage.removeItem("token");
      location.reload();
    }
    if (error.response.data.code === 401) {
      localStorage.removeItem("token");
      location.reload();
    }
    return Promise.reject(error);
  }
);
