<template>
  <div :class="flexClasses">
    <slot />
  </div>
</template>

<script setup>
import { computed } from "vue";

// Определяем свойства компонента
const props = defineProps({
  justify: {
    type: String,
    default: "start",
  },
  align: {
    type: String,
    default: "center",
  },
  direction: {
    type: String,
    default: "row",
  },
  wrap: {
    type: String,
    default: "nowrap",
  },
  gap: {
    type: String,
    default: null,
  },
  max: {
    type: Boolean,
    default: false,
  },
  className: {
    type: String,
    default: "",
  },
});

// Определяем классы
const justifyClasses = {
  start: "justifyStart",
  center: "justifyCenter",
  end: "justifyEnd",
  between: "justifyBetween",
};

const alignClasses = {
  start: "alignStart",
  center: "alignCenter",
  end: "alignEnd",
  stretch: "stretch",
};

const directionClasses = {
  row: "directionRow",
  column: "directionColumn",
};

const gapClasses = {
  4: "gap4",
  8: "gap8",
  12: "gap12",
  16: "gap16",
  20: "gap20",
  24: "gap24",
  28: "gap28",
  32: "gap32",
  64: "gap64",
};

// Вычисляем классы
const flexClasses = computed(() => [
  "flex",
  props.className,
  justifyClasses[props.justify],
  alignClasses[props.align],
  directionClasses[props.direction],
  props.wrap,
  props.gap ? gapClasses[props.gap] : null,
  { max: props.max },
]);
</script>

<style scoped>
.flex {
  display: flex;
}

.justifyStart {
  justify-content: flex-start;
}

.justifyCenter {
  justify-content: center;
}

.justifyEnd {
  justify-content: flex-end;
}

.justifyBetween {
  justify-content: space-between;
}

.alignStart {
  align-items: flex-start;
}

.alignCenter {
  align-items: center;
}

.alignEnd {
  align-items: flex-end;
}

.alignStretch {
  align-items: stretch;
}

.directionRow {
  flex-direction: row;
}

.directionColumn {
  flex-direction: column;
}

.gap4 {
  gap: 4px;
}

.gap8 {
  gap: 8px;
}

.gap12 {
  gap: 12px;
}

.gap16 {
  gap: 16px;
}

.gap20 {
  gap: 20px;
}

.gap24 {
  gap: 24px;
}

.gap28 {
  gap: 28px;
}

.gap32 {
  gap: 32px;
}

.gap64 {
  gap: 64px;
}

.max {
  width: 100%;
}

.wrap {
  flex-wrap: wrap;
}

.nowrap {
  flex-wrap: nowrap;
}
</style>
