<template>
  <div class="table-container">
    <DataTable
      v-model:selection="internalSelection"
      @rowSelect="onRowSelect"
      :value="props.data"
      :rows="10"
      dataKey="id"
      scrollable
      :selectionMode="isSelectable ? 'single' : 'null'"
      :loading="loading"
    >
      <template #loading><Loading /></template>
      <template #empty><NoDataTable /></template>
      <Column
        v-for="col of columnsData"
        :key="col.field"
        :field="col.field"
        :frozen="col.frozen"
        :header="col.header"
        :sortable="col.sortable"
        :showFilterMatchModes="false"
        :selection-mode="col.field === 'id' && showCheckbox ? 'multiple' : null"
      >
        <template
          #body="{ data }"
          v-if="
            col.field === 'status' ||
            col.field === 'id' ||
            col.field === 'synchronized'
          "
        >
          <div v-if="col.field === 'synchronized'" class="unsync">
            <img
              v-if="data.synchronized !== 'done'"
              src="@/assets/images/icons/no-sync.svg"
              alt="No Sync"
            />
          </div>
          <Status v-if="col.field === 'status'" :status="data.status" />
          <div class="table-id-column" v-if="col.field === 'id'">
            <Checkbox
              class="table-checkbox"
              v-if="showCheckbox"
              :value="data"
              v-model="internalSelection"
              @click.stop
            />
            <span class="table-id" :class="showCheckbox ? 'hiddable' : ''">
              {{ data.id }}
            </span>
          </div>
        </template>
      </Column>
      <slot name="customColumns" :item="data" />
      <Column style="width: 3rem" v-if="actions">
        <template #body="{ data }">
          <div class="table-actions">
            <slot name="actions" :item="data" />
            <Dropdown v-if="$slots.dropdownActions">
              <template #trigger>
                <Button
                  icon="pi-ellipsis-h"
                  color="secondary"
                  variant="outlined"
                  rounded="sm"
                  size="sm"
                />
              </template>
              <template #content>
                <slot name="dropdownActions" :item="data" />
              </template>
            </Dropdown>
          </div>
        </template>
      </Column>
    </DataTable>
  </div>
</template>

<script setup>
import { computed, watch } from "vue";
import Status from "@/components/Status";
import Dropdown from "@/components/Dropdown";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Button from "@/components/ui/Button/Button.vue";
import NoDataTable from "./NoDataTable";
import Loading from "../Loading";
import { ref } from "vue";
import Checkbox from "primevue/checkbox";

const props = defineProps({
  modelValue: Array,
  name: {
    type: String,
    default: "",
  },
  searchPlaceholder: {
    type: String,
    default: "Поиск по таблице",
  },
  columns: {
    type: Object,
    default: () => {},
  },
  data: {
    type: Array,
    default: () => [],
  },
  loading: {
    type: Boolean,
    default: false,
  },
  isSelectable: {
    type: Boolean,
    default: false,
  },
  actions: {
    type: Boolean,
    default: true,
  },
  showCheckbox: {
    type: Boolean,
    default: false,
  },
});
const emit = defineEmits(["RowSelect", "update:modelValue"]);
const internalSelection = ref([]);

watch(
  () => props.modelValue,
  (newValue) => {
    internalSelection.value = newValue || [];
  },
  { immediate: true, deep: true }
);

// Отправка обновлений в родительский компонент
watch(internalSelection, (newSelection) => {
  emit("update:modelValue", newSelection);
});

const columnsData = computed(() => {
  return Object.keys(props.columns).map((key) => {
    return {
      field: key,
      header: props.columns[key].value,
      sortable: !!props.columns[key].sortable,
      filterable: props.columns[key].filterable,
      frozen: props.columns[key].frozen,
    };
  });
});

const onRowSelect = (event) => {
  if (!props.isSelectable) {
    return;
  }
  emit("RowSelect", event);
};
</script>

<style scoped lang="scss">
@import "@/assets/scss/_vars";

:deep(.p-datatable) {
  .p-button.p-button-secondary.p-button-text {
    padding: 0;
    width: 36px;
    height: 36px;
  }
  .p-datatable-header {
    border-width: 0 !important;
    padding: 0;
    background: none;
  }
  .p-datatable-wrapper {
    border-radius: 8px;
  }

  .p-column-header-content {
    width: max-content;
  }
  .p-sortable-column-icon {
    color: #6c757d !important;
  }
  .p-column-filter-menu-button {
    color: #6c757d !important;
  }

  .p-datatable-thead > tr > th {
    height: 56px !important;
    background: #ebeef6 !important;
    color: #131723 !important;
    font-size: 18px;
    font-weight: 400;
    line-height: 28px;
    border: none;

    &:first-child {
      border-radius: 8px 0 0 0 !important;
    }
    &:last-child {
      border-radius: 0 8px 0 0 !important;
    }
  }
  .p-datatable-tbody > tr {
    max-height: 72px !important;
    overflow: hidden !important;
    color: #3e445b;
    font-size: 18px;
    cursor: pointer;

    &.p-highlight {
      background-color: white;
      color: $theme800;
    }
    &:hover {
      background: #f8f9fc !important;

      .table-id.hiddable {
        display: none;
      }
      .table-checkbox {
        display: block;
      }

      .table-actions {
        > * {
          opacity: 1;
        }
      }
    }
  }
  .p-datatable-tbody > td {
    display: flex;
    align-content: center;
    align-items: center;
    flex-wrap: nowrap;
  }

  .p-datatable-tbody > tr > td {
    height: 72px;
    border: 1px dotted #d4dbe8 !important;
    border-width: 0 0 1px 0 !important;
  }

  @media (max-width: 768px) {
    .p-datatable-thead > tr > th {
      height: 48px !important;
      font-size: 16px;
      line-height: 24px;
    }

    .p-datatable-tbody > tr > td {
      height: 60px;
      font-size: 14px;
    }

    .p-datatable-tbody > tr {
      max-height: 60px !important;
    }
  }

  @media (max-width: 480px) {
    .p-datatable-header {
      flex-direction: column;
      align-items: flex-start;
    }
    .p-button {
      margin-top: 8px;
      width: 100%;
    }

    .p-datatable-thead > tr > th {
      height: 40px !important;
      font-size: 14px;
      line-height: 20px;
    }

    .p-datatable-tbody > tr > td {
      height: 50px;
      font-size: 12px;
    }

    .p-datatable-tbody > tr {
      max-height: 50px !important;
    }

    .nodata {
      justify-content: flex-start;
      margin: 0;
      width: fit-content;

      img {
        margin-right: 10px;
        max-width: 80vw;
      }

      span {
        font-size: 14px;
      }
    }
  }
}
.button-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.table-actions {
  display: flex;
  gap: 24px;

  :slotted(button) {
    opacity: 0;
    transition: opacity 0.25s ease;
  }
}

.table-id-column {
  .table-id {
    width: 24px;
    display: block;
  }
  .table-checkbox {
    display: none;

    &.p-checkbox-checked {
      display: block;

      & + span {
        display: none;
      }
    }
  }
}

.unsync {
  position: relative;
  img {
    position: absolute;
    right: -20px;
    bottom: -10px;
  }
}
</style>
