import { AccountsAPI } from "@/api/AccountsAPI";
import notifications from "@/helpers/notifications";
import { notify } from "@kyvg/vue3-notification";

export const deleteAccount = (id) => {
  AccountsAPI.delete(id)
    .then(() => {
      notify({
        type: "success",
        text: "Удалено",
      });
      return true;
    })
    .catch((err) => {
      notifications(err);
      return false;
    });
};
