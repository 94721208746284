import { DefaultAPIInstance } from "@/api";

export const AccountsAPI = {
  add(payload) {
    return DefaultAPIInstance.post("/accounts", payload);
  },

  getAll() {
    return DefaultAPIInstance.get("/accounts");
  },

  getById(id) {
    return DefaultAPIInstance.get(`/accounts/${id}`);
  },

  update(id, payload) {
    return DefaultAPIInstance.patch(`/accounts/${id}`, payload);
  },

  delete(id) {
    return DefaultAPIInstance.delete(`/accounts/${id}`);
  },

  block(id) {
    return DefaultAPIInstance.patch(`/accounts/${id}/block`);
  },

  unblock(id) {
    return DefaultAPIInstance.patch(`/accounts/${id}/unblock`);
  },

  changePassword(id, payload) {
    return DefaultAPIInstance.patch(
      `/accounts/${id}/change-password/${payload}`
    );
  },

  changePeriod(id) {
    return DefaultAPIInstance.patch(`/accounts/${id}/change-period`);
  },

  syncAll(id) {
    return DefaultAPIInstance.patch(`/accounts/employee/${id}/manualSync`);
  },

  syncById(id) {
    return DefaultAPIInstance.patch(`/accounts/${id}/manualSync`);
  },
};
