<template>
  <Card>
    <template #title>
      <HStack gap="24" class="user-header">
        <h2>Общая информация</h2>
        <Button
          icon="pi-pencil"
          variant="outlined"
          color="default"
          @click="employeesStore.setEditMenu(true)"
          :style="{
            width: '180px',
          }"
          >Редактировать</Button
        >
        <Button
          variant="outlined"
          color="default"
          icon="pi-sort-alt"
          :style="{
            width: '131px',
          }"
          >Перевод</Button
        >
        <FireEmployee :inactive="employee?.inactive_status" />
      </HStack>
    </template>
    <template #content>
      <HStack align="stretch" gap="64">
        <div class="user-avatar">
          <img src="../../model/assets/avatar.png" alt="Avatar" />
        </div>
        <VStack justify="between" class="user-info">
          <div class="user-title">
            <h3>
              {{
                `${employee?.surname} ${employee?.firstName} ${employee?.secondName}`
              }}
            </h3>
            <p>
              {{ `${employee?.departmentName}, ${employee?.positionName}` }}
            </p>
          </div>
          <HStack class="user-meta">
            <div class="user-meta-item">
              <h4>Табельный номер</h4>
              <p>{{ employee?.id }}</p>
            </div>
            <div class="user-meta-item">
              <h4>E-mail</h4>
              <p>{{ employee?.email }}</p>
            </div>
            <div class="user-meta-item">
              <h4>Дата рождения:</h4>
              <p>{{ employee?.birthDate }}</p>
            </div>
          </HStack>
        </VStack>
      </HStack>
    </template>
  </Card>
</template>

<script setup>
import { useEmployeesStore } from "../../model/store/useEmployeesStore";
import Button from "@/components/ui/Button/Button.vue";
import Card from "primevue/card";
import HStack from "@/components/ui/Stack/HStack.vue";
import FireEmployee from "../components/FireEmployee.vue";
import VStack from "@/components/ui/Stack/VStack.vue";

const employeesStore = useEmployeesStore();

defineProps({
  employee: Object,
});
</script>

<style scoped lang="scss">
@import "@/assets/scss/_vars";

.user-header {
  h2 {
    flex: 1;
  }
}

.user-avatar {
  border-radius: 9px;
}

.user-title {
  h3 {
    font-size: 18px;
    line-height: 28px;
    margin-bottom: 4px;
    color: $theme800;
  }
  p {
    color: $theme500;
    font-size: 16px;
    line-height: 24px;
  }
}

.user-meta {
  gap: 40px;
  &-item {
    border-left: 1px solid $primary;
    padding-left: 12px;
    h4,
    p {
      font-size: 16px;
      line-height: 24px;
      font-weight: 400;
    }
    h4 {
      color: $theme500;
      margin-bottom: 4px;
    }
    p {
      color: $theme800;
    }
  }
}
</style>
