<template>
  <div
    class="group-modal"
    :class="{ open: showGroups }"
    ref="modal"
    :style="{ left: `${position.left}px` }"
  >
    <slot />
  </div>
</template>

<script setup>
import { onMounted, ref, watch } from "vue";

const props = defineProps({
  showGroups: Boolean,
});

const position = ref({ left: 0 });
const modal = ref(null);

// Проверка на выход списка групп за пределы браузерного окна
const checkModalBounds = () => {
  if (!modal.value) return;

  const modalRect = modal.value.getBoundingClientRect();
  const viewportWidth = window.innerWidth;

  // Проверка только правой границы
  if (modalRect.right > viewportWidth) {
    position.value.left = viewportWidth - modalRect.right - 32;
  }
};

onMounted(() => {
  checkModalBounds();
});

watch(props.showGroups.value, (visible) => {
  if (visible) checkModalBounds();
});
</script>

<style scoped lang="scss">
@import "@/assets/scss/_vars";
@import "@/assets/scss/_mixins";

.group-modal {
  opacity: 0;
  width: 400px;
  position: absolute;
  bottom: -24px;
  transform: translateY(-100%);
  z-index: 999;
  background-color: white;
  border-radius: 9px;
  box-shadow: 0px 0px 1px 0px rgba(19, 23, 35, 0.04),
    0px 2px 6px 0px rgba(19, 23, 35, 0.04),
    0px 16px 24px 0px rgba(19, 23, 35, 0.04);
  transition: opacity 0.25s ease;

  &.open {
    transform: translateY(100%);
    opacity: 1;
  }
}
</style>
