<template>
  <h3 class="form-title">Заполните общую информацию</h3>

  <div class="form-common form-block">
    <FormInput
      v-model="formData.name"
      type="text"
      label="Наименование учетной записи"
      size="small"
      placeholder="Введите наименование учетной записи"
      :error-condition="formErrors.name"
    />
    <FormSelect
      v-model="formData.serviceId"
      :items="services"
      label="Сервис"
      placeholder="Выберите сервис"
      searchable
      :error-condition="formErrors.serviceId"
    />
    <FormSelect
      v-model="formData.renewalPeriod"
      :items="updatePeriods"
      label="Укажите период обновления"
      placeholder="Укажите период обновления"
      :error-condition="formErrors.renewalPeriod"
    />
    <FormSelect
      v-model="formData.employeeId"
      :items="employees"
      label="ФИО Пользователя"
      placeholder="Укажите ФИО Пользователя"
      searchable
      :error-condition="formErrors.employeeId"
    />
  </div>

  <h3 class="form-title">Укажите период действия</h3>

  <div class="form-block">
    <HStack gap="32" class="form-radio form-block">
      <HStack gap="16">
        <RadioButton
          v-model="isTemporary"
          inputId="period1"
          name="period"
          :value="false"
        />
        <label for="period1">Постоянный</label>
      </HStack>

      <HStack gap="16">
        <RadioButton
          v-model="isTemporary"
          inputId="period2"
          name="period"
          :value="true"
        />
        <label for="period2">Временный</label>
      </HStack>
    </HStack>

    <h4 class="form-subtitle" v-if="isTemporary">Период</h4>

    <HStack gap="24" v-if="isTemporary">
      <FormDate
        v-model="formData.activeFrom"
        :error-condition="formErrors.activeFrom"
      />

      <FormDate
        v-model="formData.activeTo"
        :error-condition="formErrors.activeTo"
      />
    </HStack>
  </div>

  <AccountActivation
    class="form-block"
    :active="formData.active"
    @toggle-activation="toggleActivation"
  />

  <h3 class="form-title">Роль учетной записи</h3>

  <div class="form-block">
    <div class="form-role" v-for="(role, index) in formData.roles" :key="index">
      <FormSelect
        v-model="formData.roles[index]"
        :items="roles"
        label="Роль"
        placeholder="Укажите роль"
        searchable
      />
    </div>

    <Button
      variant="outlined"
      @click="addRole"
      :style="{
        width: '190px',
      }"
    >
      Добавить роль
    </Button>

    <span class="form-error" v-if="formErrors.roles"
      >Не выбрано ни одной роли</span
    >
  </div>

  <h3 class="form-title">Создайте пароль для учетной записи</h3>

  <div class="form-block">
    <FormInput
      v-model="formData.password"
      type="password"
      label="Пароль"
      :validation="{}"
      :disabled="true"
      v-if="formData.password"
      :error-condition="formErrors.password"
    />
    <Button
      variant="outlined"
      @click="isShowPasswordModal = true"
      v-if="!formData.password"
      :style="{
        width: '190px',
      }"
    >
      Создать пароль
    </Button>
    <Button
      variant="outlined"
      @click="isShowPasswordModal = true"
      v-if="formData.password"
      :style="{
        width: '206px',
      }"
    >
      Изменить пароль
    </Button>
    <span class="form-error" v-if="formErrors.password">Пароль не указан</span>
  </div>

  <ChangePassword
    v-if="isShowPasswordModal"
    @close="isShowPasswordModal = false"
    @submit="changePassword"
    :password="formData.password"
  />
</template>

<script setup>
import { updatePeriods } from "@/enums/enum-accounts";
import Button from "@/components/ui/Button/Button.vue";
import ChangePassword from "../components/ChangePassword.vue";
import { onMounted, ref } from "vue";
import { ServicesAPI } from "@/api/ServicesAPI";
import { EmployeesAPI } from "@/api/EmployeesAPI";
import { RolesAPI } from "@/api/RolesAPI";
import AccountActivation from "../components/AccountActivation.vue";
import HStack from "@/components/ui/Stack/HStack.vue";
import RadioButton from "primevue/radiobutton";

defineExpose({
  getFormValues: () => {
    if (!validateForm()) {
      return;
    }
    return formData.value;
  },
});

const props = defineProps({
  account: { type: Object, default: () => ({}) },
});

const isShowPasswordModal = ref(false);
const isTemporary = ref(props.account.temporary);
const services = ref([]);
const employees = ref([]);
const roles = ref([]);
const formData = ref({
  serviceId: props.account?.serviceId || "",
  employeeId: props.account?.employeeId || "",
  name: props.account?.name || "",
  password: props.account?.password || "",
  renewalPeriod: props.account?.renewalPeriod || "",
  active: props.account?.active || false,
  activeFrom: props.account?.activeFrom || "",
  activeTo: props.account?.activeTo || "",
  roles: props.account?.roles || [],
});

const formErrors = ref({
  serviceId: false,
  employeeId: false,
  name: false,
  password: false,
  renewalPeriod: false,
  roles: false,
});

const addRole = () => {
  formData.value.roles.push(null);
};

const validateForm = () => {
  // Проходим только по ключам, которые указаны в formErrors
  for (let key of Object.keys(formErrors.value)) {
    if (key === "roles") {
      // Проверка для поля roles (массив)
      if (formData.value[key].length === 0) {
        formErrors.value[key] = true; // Если массив пуст, ставим ошибку
      } else {
        formErrors.value[key] = false; // Если массив не пуст, убираем ошибку
      }
    } else {
      // Для остальных полей проверка на пустоту
      if (formData.value[key] === "" || formData.value[key] === null) {
        formErrors.value[key] = true; // Если значение пустое или null, ставим ошибку
      } else {
        formErrors.value[key] = false; // Если значение есть, убираем ошибку
      }
    }
  }

  // Если хотя бы одно поле в formErrors имеет значение true, форма невалидна
  if (Object.values(formErrors.value).includes(true)) {
    return false;
  }

  return true;
};

const changePassword = (password) => {
  isShowPasswordModal.value = false;
  formData.value.password = password;
};

const toggleActivation = (payload) => {
  formData.value.active = payload;
};

const getServices = () => {
  ServicesAPI.getAll().then((res) => {
    services.value = res.data.map((item) => ({
      id: item.id,
      key: item.name,
    }));
  });
};

const getEmployees = () => {
  EmployeesAPI.getAll().then((res) => {
    employees.value = res.data.map((item) => ({
      id: item.id,
      key: `${item.surname} ${item.firstName} ${item.secondName}`,
    }));
  });
};

const getRoles = () => {
  RolesAPI.getAll().then((res) => {
    roles.value = res.data.map((item) => ({
      id: item.id,
      key: item.name,
    }));
  });
};

onMounted(() => {
  getServices();
  getEmployees();
  getRoles();
});
</script>

<style scoped lang="scss">
@import "@/assets/scss/_vars";

.form-block {
  margin-bottom: 40px;
}

.form-common {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 24px;
}

.form-title {
  margin-bottom: 24px;
  padding-bottom: 16px;
  border-bottom: 1px solid $theme200;
  font-size: 24px;
  line-height: 32px;
}

.form-subtitle {
  margin-bottom: 16px;
  font-size: 18px;
  line-height: 28px;
}

.form-error {
  display: block;
  color: $danger;
  font-size: 12px;
  margin-top: 5px;
}
</style>
