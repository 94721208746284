<template>
  <Card>
    <template #title>
      <HStack gap="16" class="group-header">
        <Button
          variant="outlined"
          color="transparent"
          icon="pi-chevron-left"
          @click="closeGroupHandler"
        />
        <h2>{{ group.title }}</h2>
        <Dropdown>
          <template #trigger>
            <Button icon="pi-ellipsis-h" color="secondary" variant="outlined" />
          </template>
          <template #content>
            <li @click="groupMode = 'exclude'">Исключить из группы</li>
            <li @click="groupMode = 'include'">Добавить в группу</li>
            <li @click="deleteGroupHandler(group.id)">Удалить группу</li>
          </template>
        </Dropdown>
      </HStack>
    </template>
    <template #content>
      <Table
        name="accounts"
        :columns="columns"
        :data="group.items"
        isSelectable
      >
        <template #dropdownActions="{ item }">
          <slot name="tableActions" :item="item" />
          <li @click="deleteFromGroup(item.id)">Удалить</li>
        </template>
      </Table>
    </template>
  </Card>
  <GroupMenu
    v-if="groupMode"
    :mode="groupMode"
    :group="group"
    :allItems="allItems"
    @close="groupMode = null"
    @updateGroup="updateGroupItems"
  />
</template>

<script setup>
import Card from "primevue/card";
import HStack from "@/components/ui/Stack/HStack.vue";
import Table from "@/components/table/Table.vue";
import Button from "../Button/Button.vue";
import { ref } from "vue";
import Dropdown from "@/components/Dropdown.vue";
import GroupMenu from "./_GroupMenu.vue";

const groupMode = ref(null); // Режим работы с группой: "exclude", "include" или null

const props = defineProps({
  group: Object, // Группа, формат - {id: 1, title: "Group", items: [...]}
  allItems: Array, // Полный список элементов
  columns: Object, // Конфигурация колонок таблицы
});

const emit = defineEmits(["closeGroup", "changeGroup", "deleteGroup"]);

// Закрытие группы через emit
const closeGroupHandler = () => {
  emit("closeGroup");
};

// Удаление группы по ID через emit
const deleteGroupHandler = (id) => {
  emit("deleteGroup", id);
};

// Обработчик удаления группы
const updateGroupItems = (updatedItems) => {
  emit("changeGroup", updatedItems);
  groupMode.value = null;
};

const deleteFromGroup = (id) => {
  emit(
    "changeGroup",
    props.group.items.filter((item) => item.id !== id)
  );
};
</script>

<!-- Пример использования -->

<!-- 
<Table
  v-if="!currentGroup"
  v-model="selectedEmployees"
  :showCheckbox="true"
/>
<GroupView
  v-else
  :group="currentGroup"
  :allItems="employees"
  :columns="columns"
  @close-group="currentGroup = null"
  @changeGroup="updateGroup"
  @deleteGroup="deleteGroup"
/>

=================================

const employees = ref([]);
const groupList = ref([]);
const selectedEmployees = ref([]);
const currentGroup = ref(null);

const updateGroup = (items, id) => {
  const group = groupList.value.find((group) => group.id === id);
  if (group) {
    group.items = items;
  }
  if (currentGroup.value) {
    currentGroup.value.items = items;
  }
};

const deleteGroup = (id) => {
  const index = groupList.value.findIndex((item) => item.id === id);
  if (index !== -1) {
    groupList.value.splice(index, 1);
  }
  if (currentGroup.value.id === id) {
    currentGroup.value = null;
  }
};
-->

<style scoped lang="scss">
@import "@/assets/scss/_vars";

.group-header {
  h2 {
    width: 100%;
  }
}
</style>
