import { createRouter, createWebHistory } from "vue-router";
import { nextTick } from "vue";
import guest from "@/middleware/guest";
import auth from "@/middleware/auth";
import permissions from "@/middleware/permissions";
import middlewarePipeline from "./middlewarePipeline";
import SignIn from "@/views/SignIn/SignIn.vue";

import Employees from "@/views/Employees/Index";
import EmployeesView from "@/views/Employees/View";
import Accounts from "@/views/Accounts/Index";
import Roles from "@/views/Roles/Index";
import NoAccess from "@/views/SignIn/Noaccess.vue";
import Main from "@/views/Main/Index";
import CatalogAdd from "@/views/Roles/ui/pages/catalog/Add.vue";
import CatalogView from "@/views/Roles/ui/pages/catalog/View.vue";
import RolesView from "@/views/Roles/View.vue";

const routes = [
  {
    path: "/main",
    name: "main",
    component: Main,
    meta: {
      layout: "DefaultLayout",
      title: "Добро пожаловать в CTfind IDM!",
      middleware: [auth],
      breadcrumb: "Главная",
    },
  },
  {
    path: "/signin",
    name: "login",
    component: SignIn,
    meta: {
      layout: "AuthLayout",
      title: "Авторизация",
      middleware: [guest],
    },
  },

  // Employees
  {
    path: "/employees",
    name: "employees",
    component: Employees,
    meta: {
      breadcrumb: "Пользователи",
      addPath: "/employees/add",
      layout: "DefaultLayout",
      permission: "employees:list",
      title: "Пользователи",
      middleware: [auth, permissions],
    },
  },
  {
    path: "/employees/:id",
    name: "employeesView",
    component: EmployeesView,
    meta: {
      breadcrumb: "Просмотр пользователя",
      addPath: "/employees/add",
      layout: "DefaultLayout",
      permission: "employees:edit",
      title: "Пользователь",
      middleware: [auth, permissions],
    },
  },

  // Accounts
  {
    path: "/accounts",
    name: "accounts",
    component: Accounts,
    meta: {
      breadcrumb: "Учетные записи",
      layout: "DefaultLayout",
      title: "Учетные записи",
      middleware: [auth, permissions],
    },
  },
  // Roles
  {
    path: "/roles",
    name: "roles",
    component: Roles,
    meta: {
      breadcrumb: "Управление ролями",
      layout: "DefaultLayout",
      permission: "roles:list",
      title: "Управление ролями",
      middleware: [auth, permissions],
    },
  },
  {
    path: "/roles/:id",
    name: "rolesView",
    component: RolesView,
    meta: {
      breadcrumb: "Просмотр роли",
      addPath: "/roles/add",
      layout: "DefaultLayout",
      permission: "roles:view",
      middleware: [auth, permissions],
    },
  },
  {
    path: "/roles/catalog/add",
    name: "rolesAddSect",
    component: CatalogAdd,
    meta: {
      breadcrumb: "Добавить раздел каталога",
      layout: "DefaultLayout",
      permission: "roles:create",
      title: "Добавить раздел каталога",
      middleware: [auth, permissions],
    },
  },
  {
    path: "/roles/catalog/:id",
    name: "rolesViewSection",
    component: CatalogView,
    meta: {
      breadcrumb: "Просмотр раздела каталога",
      addPath: "/roles/add",
      layout: "DefaultLayout",
      permission: "roles:view",
      middleware: [auth, permissions],
    },
  },
  {
    path: "/noaccess",
    name: "noaccess",
    component: NoAccess,
    meta: {
      layout: "DefaultLayout",
      middleware: [auth],
    },
  },
  { path: "/:catchAll(.*)", redirect: "/employees" },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  if (!to.meta.middleware) {
    return next();
  }
  const middleware = to.meta.middleware;
  const context = {
    to,
    from,
    next,
  };
  return middleware[0]({
    ...context,
    next: middlewarePipeline(context, middleware, 1),
  });
});

router.afterEach(() => {
  nextTick(() => {
    document.title = "CTfind IDM";
  });
});

export default router;
