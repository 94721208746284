<template>
  <Card>
    <template #title>
      <h3 class="title">Учетные записи</h3>
      <Button
        variant="outlined"
        color="default"
        icon="pi-history"
        @click="updateHandler"
        :style="{
          width: '138px',
        }"
        >Обновить</Button
      >
      <AddAccount />
    </template>
    <template #content>
      <Table :columns="accountsColumn" :data="accounts" :loading="loading">
        <template #dropdownActions="{ item }">
          <li
            @click="employeesStore.setShowActivateAccount('activate')"
            :aria-disabled="item.status.value === 'active'"
          >
            Разблокировать
          </li>
          <li
            @click="employeesStore.setShowActivateAccount('deactivate')"
            :aria-disabled="item.status.value === 'inactive'"
          >
            Заблокировать
          </li>
          <li @click="employeesStore.setShowPswdModal(true)">
            Изменить пароль
          </li>
        </template>
      </Table>
    </template>
  </Card>

  <AccountActivation />
  <ChangePassword password="password" />
</template>

<script setup>
import Table from "@/components/table/Table.vue";
import { ref } from "vue";
import { useEmployeesStore } from "../../model/store/useEmployeesStore";
import AccountActivation from "../components/AccountActivation.vue";
import ChangePassword from "../components/ChangePassword.vue";
import Button from "@/components/ui/Button/Button.vue";
import AddAccount from "../components/AddAccount.vue";
import Card from "primevue/card";

const employeesStore = useEmployeesStore();

const accounts = ref([]);

const accountsColumn = {
  id: { value: "ID" },
  name: { value: "Наименование" },
  service: { value: "Сервис" },
  date: { value: "Дата создания" },
  lastLogin: { value: "Последний вход" },
  status: { value: "Статус" },
};

defineProps({
  accounts: Array,
});
</script>
