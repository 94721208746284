<template>
  <div class="account-block account-grid">
    <div class="account-title">
      <h3>Общая информация</h3>
      <Button
        variant="outlined"
        color="default"
        @click="syncAccountById(formData?.id)"
        :style="{
          width: '211px',
        }"
      >
        Синхронизировать</Button
      >
      <Button
        icon="pi-pencil"
        variant="outlined"
        :color="editMode ? 'primary' : 'default'"
        @click="accountsStore.toggleEditMode()"
      />
      <Button
        icon="pi-trash"
        variant="outlined"
        color="default"
        @click="accountsStore.setDeleteModal(true)"
      />
    </div>
    <div class="account-block-item">
      <span>Наименование учетной записи</span>
      <p>{{ formData?.name }}</p>
    </div>
    <div class="account-block-item">
      <span>Ресурс</span>
      <p>{{ formData?.serviceName }}</p>
    </div>
    <div class="account-block-item">
      <span>ФИО Пользователя</span>
      <p>{{ formData?.employeeName }}</p>
    </div>
    <div class="account-block-item">
      <span>Дата создания</span>
      <p>{{ formData?.createdAt }}</p>
    </div>
    <div class="account-block-item">
      <span>Последний вход</span>
      <p>{{ formData?.lastLoginAt }}</p>
    </div>
    <div class="account-block-item">
      <span>Дата и время последнего обновления</span>
      <p>{{ formData?.lastUpdateAt }}</p>
    </div>
    <div v-if="!editMode" class="account-block-item">
      <span>Период обновления</span>
      <p>{{ formData?.renewalPeriod }}</p>
    </div>
    <FormSelect
      v-else
      v-model="formData.renewalPeriod"
      :items="updatePeriods"
      label="Укажите период обновления"
      placeholder="Укажите период обновления"
    />
  </div>
  <div v-if="!editMode" class="account-block">
    <div class="account-title">
      <h3>Период действия</h3>
    </div>
    <div class="account-block-item">
      <span>Период</span>
      <p v-if="!formData?.temporary">Бессрочно</p>
      <p v-else>{{ formData?.activeFrom }} - {{ formData?.activeTo }}</p>
    </div>
  </div>
  <div v-else class="account-block">
    <div class="account-title">
      <h3>Укажите период действия</h3>
    </div>
    <HStack gap="32" class="account-radio">
      <HStack gap="16">
        <RadioButton
          v-model="isTemporary"
          inputId="period1"
          name="period"
          :value="false"
        />
        <label for="period1">Постоянный</label>
      </HStack>

      <HStack gap="16">
        <RadioButton
          v-model="isTemporary"
          inputId="period2"
          name="period"
          :value="true"
        />
        <label for="period2">Временный</label>
      </HStack>
    </HStack>

    <h4 class="form-subtitle" v-if="isTemporary">Период</h4>

    <HStack gap="24" v-if="isTemporary">
      <FormDate
        v-model="formData.activeFrom"
        :error-condition="formErrors.activeFrom"
      />

      <FormDate
        v-model="formData.activeTo"
        :error-condition="formErrors.activeTo"
      />
    </HStack>
  </div>
  <AccountActivation
    class="account-block"
    :active="formData?.active"
    @toggleActivation="blockHandler"
  />
  <div v-if="!editMode" class="account-block">
    <div class="account-title">
      <h3>Роли учетной записи</h3>
    </div>
    <div
      class="account-block-item"
      v-for="role in formData?.roles"
      :key="role.id"
    >
      <span>Роль</span>
      <p>{{ role.name }}</p>
    </div>
  </div>

  <div v-else class="account-block">
    <div class="account-title">
      <h3>Роли учетной записи</h3>
    </div>
    <div class="form-role" v-for="(role, index) in formData.roles" :key="index">
      <FormSelect
        v-model="formData.roles[index]"
        :items="roles"
        label="Роль"
        placeholder="Укажите роль"
        searchable
      />
    </div>

    <Button
      variant="outlined"
      @click="addRole"
      :style="{
        width: '190px',
      }"
    >
      Добавить роль
    </Button>

    <span class="form-error" v-if="formErrors.roles"
      >Не выбрано ни одной роли</span
    >
  </div>

  <div class="account-block">
    <div class="account-title">
      <h3>Пароль учетной записи</h3>
    </div>
    <FormInput
      v-model="formData.password"
      type="password"
      label="Пароль"
      :validation="{}"
      :disabled="true"
      v-if="formData.password"
      :error-condition="formErrors.password"
    />
    <Button
      variant="outlined"
      @click="isShowPasswordModal = true"
      v-if="!formData.password"
      :style="{
        width: '190px',
      }"
    >
      Создать пароль
    </Button>
    <Button
      variant="outlined"
      @click="isShowPasswordModal = true"
      v-if="formData.password"
      :style="{
        width: '206px',
      }"
    >
      Изменить пароль
    </Button>
    <span class="form-error" v-if="formErrors.password">Пароль не указан</span>
  </div>

  <ChangePassword
    v-if="isShowPasswordModal"
    @close="isShowPasswordModal = false"
    @submit="changePassword"
    :password="formData.password"
  />
</template>

<script setup>
import Button from "@/components/ui/Button/Button.vue";
import AccountActivation from "../components/AccountActivation.vue";
import { syncAccountById } from "../../model/api/syncAccount";
import { useAccountsStore } from "../../model/store/useAccountsStore";
import HStack from "@/components/ui/Stack/HStack.vue";
import { onMounted, ref } from "vue";
import RadioButton from "primevue/radiobutton";
import { RolesAPI } from "@/api/RolesAPI";
import { AccountsAPI } from "@/api/AccountsAPI";
import { notify } from "@kyvg/vue3-notification";
import notifications from "@/helpers/notifications";
import ChangePassword from "../components/ChangePassword.vue";
import { updatePeriods } from "@/enums/enum-accounts";

const props = defineProps({
  account: Object,
  editMode: Boolean,
});

defineExpose({
  getFormValues: () => {
    if (!validateForm()) {
      return;
    }
    return formData.value;
  },
});

const accountsStore = useAccountsStore();
const isTemporary = ref(false);
const roles = ref([]);
const isShowPasswordModal = ref(false);

const formData = ref({ ...props.account });

const formErrors = ref({
  serviceId: false,
  employeeId: false,
  name: false,
  password: false,
  renewalPeriod: false,
  roles: false,
});

const getRoles = () => {
  RolesAPI.getAll().then((res) => {
    roles.value = res.data.map((item) => ({
      id: item.id,
      key: item.name,
    }));
  });
};

const validateForm = () => {
  // Проходим только по ключам, которые указаны в formErrors
  for (let key of Object.keys(formErrors.value)) {
    if (key === "roles") {
      // Проверка для поля roles (массив)
      if (formData.value[key].length === 0) {
        formErrors.value[key] = true; // Если массив пуст, ставим ошибку
      } else {
        formErrors.value[key] = false; // Если массив не пуст, убираем ошибку
      }
    } else {
      // Для остальных полей проверка на пустоту
      if (formData.value[key] === "" || formData.value[key] === null) {
        formErrors.value[key] = true; // Если значение пустое или null, ставим ошибку
      } else {
        formErrors.value[key] = false; // Если значение есть, убираем ошибку
      }
    }
  }

  // Если хотя бы одно поле в formErrors имеет значение true, форма невалидна
  if (Object.values(formErrors.value).includes(true)) {
    return false;
  }

  return true;
};

const changePassword = (password) => {
  if (props.editMode) {
    formData.value.password = password;
    return;
  }
  AccountsAPI.changePassword(formData.value.id, password)
    .then(() => {
      notify({
        type: "success",
        text: "Пароль изменен",
      });
      isShowPasswordModal.value = false;
      formData.value.password = password;
    })
    .catch((err) => {
      notifications(err);
    });
};

const blockHandler = (active) => {
  if (props.editMode) {
    formData.value.active = active;
    return;
  }
  if (!active) {
    AccountsAPI.block(formData.value.id)
      .then(() => {
        notify({
          type: "success",
          text: "Учетная запись заблокирована",
        });
        window.dispatchEvent(new CustomEvent("updateAccount"));
      })
      .catch((err) => {
        notifications(err);
      });
  } else {
    AccountsAPI.unblock(formData.value.id)
      .then(() => {
        notify({
          type: "success",
          text: "Учетная запись разблокирована",
        });
        window.dispatchEvent(new CustomEvent("updateAccount"));
      })
      .catch((err) => {
        notifications(err);
      });
  }
};

onMounted(() => {
  getRoles();
});
</script>

<style scoped lang="scss">
@import "@/assets/scss/_vars";

.account-block {
  margin-bottom: 40px;
  .account-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 14px;
    padding-bottom: 16px;
    margin-bottom: 16px;
    border-bottom: 1px solid $theme200;
    h3 {
      font-size: 20px;
      line-height: 28px;
      flex: 1;
    }
  }

  &-item:not(:last-child) {
    margin-bottom: 16px;
  }

  &-item {
    span,
    p {
      font-size: 16px;
      line-height: 24px;
    }

    span {
      color: $theme500;
      margin-bottom: 4px;
    }
  }
}

.account-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);

  .account-title {
    grid-column: 1 / 3;
  }
}

.account-radio {
  margin-bottom: 24px;
}
</style>
