import { AccountsAPI } from "@/api/AccountsAPI";
import notifications from "@/helpers/notifications";
import { notify } from "@kyvg/vue3-notification";

export const changePassword = (id, password) => {
  AccountsAPI.changePassword(id, password)
    .then(() => {
      notify({
        type: "success",
        text: "Пароль успешно изменен",
      });
    })
    .catch((err) => {
      notifications(err);
    });
};
