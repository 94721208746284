<template>
  <div class="deactivation">
    <div class="deactivation__title" v-if="active">
      Заблокировать учетную запись
    </div>
    <div class="deactivation__title" v-else>Активировать учетную запись</div>
    <div class="deactivation-col">
      <p v-if="active">
        Учетная запись будет заблокирована. <br />
        доступ к платформе будет полностью ограничен
      </p>
      <p v-else>
        Учетная запись будет активирована, <br />
        доступ к сервису будет полностью доступен
      </p>
      <Button
        v-if="active"
        icon="pi-times"
        color="danger"
        @click="activationHandler(false)"
        :style="{
          width: '206px',
        }"
      >
        Заблокировать
      </Button>
      <Button
        v-else
        icon="pi-check"
        color="primary"
        @click="activationHandler(true)"
        :style="{
          width: '206px',
        }"
      >
        Активировать
      </Button>
    </div>
  </div>
</template>

<script setup>
import Button from "@/components/ui/Button/Button.vue";

defineProps({
  active: Boolean,
});

const emit = defineEmits(["toggleActivation"]);

const activationHandler = (active) => {
  emit("toggleActivation", active);
};
</script>

<style lang="scss" scoped>
.deactivation {
  border: 1px solid #dfdfdf;
  border-radius: 8px;
  padding: 20px;
  background: #f1fdff;
  &__title {
    color: #3e445b;
    font-weight: 700;
    font-size: 18px;
    margin-bottom: 20px;
  }
  &-col {
    display: flex;
    align-items: center;
    justify-content: space-between;
    p {
      line-height: 20px;
    }
  }
}
</style>
