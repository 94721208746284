import { defineStore } from "pinia";

export const useHeaderStore = defineStore("header", {
  state: () => ({
    pageTitle: null,
  }),
  actions: {
    setPageTitle(payload) {
      this.pageTitle = payload;
    },
    resetPageTitle() {
      this.pageTitle = null;
    },
  },
});
