<template>
  <SideMenu
    @close="closeMenu"
    :title="mode === 'exclude' ? 'Исключить из группы' : 'Добавить в группу'"
    width="482"
  >
    <template #body>
      <template v-if="mode === 'exclude'">
        <h3 class="group-title">{{ group.title }}</h3>
        <HStack
          v-for="item in group.items"
          :key="item.id"
          gap="16"
          class="group-item"
        >
          <Checkbox
            :id="item.id"
            :checked="checkedItems.includes(item.id)"
            @update:modelValue="toggleSelection(item.id)"
          />
          <div class="group-item-text">
            <h4 v-if="item.login">{{ item.login }}</h4>
            <h4 v-else-if="item.surname">
              {{ item.surname }} {{ item.firstName }} {{ item.secondName }}
            </h4>
            <h4 v-else-if="item.name">
              {{ item.name }}
            </h4>
            <p v-if="item.service">{{ item.service }}, {{ item.fio }}</p>
            <p v-else-if="item.departmentName">
              {{ item.departmentName }}, {{ item.positionName }}
            </p>
            <p v-else-if="item.source">{{ item.source }}, {{ item.owner }}</p>
          </div>
        </HStack>
      </template>
      <template v-else>
        <HStack
          v-for="item in uniqueItems"
          :key="item.id"
          gap="16"
          class="group-item"
        >
          <Checkbox
            :id="item.id"
            :checked="checkedItems.includes(item.id)"
            @update:modelValue="toggleSelection(item.id)"
          />
          <div class="group-item-text">
            <h4 v-if="item.login">{{ item.login }}</h4>
            <h4 v-else-if="item.surname">
              {{ item.surname }} {{ item.firstName }} {{ item.secondName }}
            </h4>
            <h4 v-else-if="item.name">
              {{ item.name }}
            </h4>
            <p v-if="item.service">{{ item.service }}, {{ item.fio }}</p>
            <p v-else-if="item.departmentName">
              {{ item.departmentName }}, {{ item.positionName }}
            </p>
            <p v-else-if="item.source">{{ item.source }}, {{ item.owner }}</p>
          </div>
        </HStack>
      </template>
    </template>
    <template #footer>
      <Button
        variant="solid"
        color="secondary"
        @click="closeMenu"
        :style="{
          width: '170px',
        }"
      >
        Отмена
      </Button>
      <Button
        v-if="mode === 'exclude'"
        @click="applyChanges"
        :style="{
          width: '206px',
        }"
      >
        Исключить
      </Button>
      <Button
        v-else
        @click="applyChanges"
        :style="{
          width: '206px',
        }"
      >
        Добавить
      </Button>
    </template>
  </SideMenu>
</template>

<script setup>
import { onMounted, ref } from "vue";
import Button from "../Button/Button.vue";
import Checkbox from "../Checkbox/Checkbox.vue";
import SideMenu from "../SideMenu/SideMenu.vue";
import HStack from "../Stack/HStack.vue";

const props = defineProps({
  mode: String,
  group: Object,
  allItems: Array,
});

const emit = defineEmits(["close", "updateGroup"]);

const checkedItems = ref([]);
const uniqueItems = ref([]);

const filterUniqueItems = () => {
  // Создаем Set из IDs элементов группы
  const existingIds = new Set(props.group.items.map((item) => item.id));

  // Исключаем из общего списка элементы, которые уже есть в группе
  uniqueItems.value = props.allItems.filter(
    (item) => !existingIds.has(item.id)
  );
};

const toggleSelection = (id) => {
  if (checkedItems.value.includes(id)) {
    // Удаляем элемент из списка выбранных
    checkedItems.value = checkedItems.value.filter((item) => item !== id);
  } else {
    // Добавляем элемент в список выбранных
    checkedItems.value.push(id);
  }
};

const applyChanges = () => {
  if (props.mode === "exclude") {
    // Исключаем выбранные элементы из группы
    emit("updateGroup", [
      ...props.group.items.filter(
        (item) => !checkedItems.value.includes(item.id)
      ),
    ]);
  } else {
    // Добавляем выбранные новые элементы в группу
    emit("updateGroup", [
      ...props.group.items,
      ...uniqueItems.value.filter((item) =>
        checkedItems.value.includes(item.id)
      ),
    ]);
  }

  // Сбрасываем состояние выбора и фильтруем уникальные элементы
  checkedItems.value = [];
};

const closeMenu = () => {
  emit("close");
};

onMounted(() => {
  filterUniqueItems(); // Фильтрация уникальных элементов при загрузке компонента
});
</script>

<style scoped lang="scss">
@import "@/assets/scss/_vars";

.group-title {
  font-size: 20px;
  line-height: 28px;
  padding-bottom: 16px;
  margin-bottom: 24px;
  border-bottom: 1px solid $theme200;
  margin-top: 32px;
}

.group-item {
  border: 1px solid $theme200;
  background-color: white;
  border-radius: 9px;
  padding: 11px 20px;
  margin-bottom: 8px;

  h4 {
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
    margin-bottom: 2px;
  }

  p {
    font-size: 12px;
    line-height: 20px;
    color: $theme500;
  }
}
</style>
