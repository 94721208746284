import { AccountsAPI } from "@/api/AccountsAPI";
import notifications from "@/helpers/notifications";
import { notify } from "@kyvg/vue3-notification";

export const blockAccount = async (id) => {
  try {
    await AccountsAPI.block(id);
    notify({
      type: "success",
      text: "Учетная запись заблокирована",
    });
  } catch (err) {
    notifications(err);
  }
};

export const unblockAccount = async (id) => {
  try {
    await AccountsAPI.unblock(id);
    notify({
      type: "success",
      text: "Учетная запись разблокирована",
    });
  } catch (err) {
    notifications(err);
  }
};
