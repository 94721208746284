export const account = {
  name: "Poliklinika_212",
  service: {
    name: "МОБ СОШ “Искра”",
    description: "Сервис для поиска в интернете",
  },
  employeeId: 12345,
  password: "password",
  active: true,
  date: "29 мая 2024",
  lastLoginAt: "2023-08-23T14:34:10Z",
  renewalPeriod: "3 месяца",
  role: "Базовая",
  permissions: "CN+Domain Employees, CN=Employees, DC=demo, DC=stand",
};

export const services = [
  { key: "МОБ СОШ “Искра”", id: "1" },
  { key: "1С, Предприятие", id: "2" },
];

export const updatePeriods = [
  { key: "3 месяца", id: "3" },
  { key: "6 месяцев", id: "6" },
  { key: "12 месяцев", id: "12" },
];

export const usernames = [
  { key: "Куликов Иван Владимирович", id: "1" },
  { key: "Куликов Иван Владимирович", id: "2" },
  { key: "Куликов Иван Владимирович", id: "3" },
];
export const roles = [{ key: "Базовая", id: "1" }];

export const accountsList = [
  {
    id: 1,
    login: "1С_ Organization45",
    service: {
      id: 1,
      name: "1С, Предприятие",
    },
    createdAt: "1990-02-10",
    lastLoginAt: "1990-02-10",
    active: true,
    synchronized: "not",
    employee: {
      id: 1,
      fio: "Петров Иван Иванович",
    },
    updateAccountDate: "1990-02-10",
  },
];
