<template>
  <div class="content">
    <Loading :show="loading" />
    <div>
      <div class="table-header">
        <SearchInput
          placeholder="Поиск по Сервису, Учетной записи и Пользователю..."
        />
        <div class="button-container">
          <GroupsList
            v-model:showGroups="isShowGroupsWindow"
            :groups="groupList"
            :all-items="accounts"
            @update:groups="updateGroups"
            :selectedItems="selectedAccounts"
            @clickGroup="filterAccountsByGroup"
            @changeGroup="updateGroup"
            @deleteGroup="deleteGroup"
          />

          <AddAccount />
        </div>
      </div>
      <Table
        v-if="!currentGroup"
        v-model="selectedAccounts"
        name="accounts"
        :columns="columns"
        :data="accounts"
        :showCheckbox="true"
        :isSelectable="true"
        @RowSelect="selectAccountHandler"
      >
        <template #dropdownActions="{ item }">
          <li @click="editHandler(item.id)">Редактировать</li>
          <li
            v-if="item.status.value === 'active'"
            @click="blockHandler(item.id, true)"
          >
            Заблокировать
          </li>
          <li v-else @click="blockHandler(item.id, false)">Разблокировать</li>
          <li @click="showPasswordHandler(item.id)">Изменить пароль</li>
          <li @click="deleteAccountHandler(item.id)">Удалить</li>
        </template>
      </Table>
      <GroupView
        v-else
        :group="currentGroup"
        :allItems="accounts"
        :columns="columns"
        @close-group="currentGroup = null"
        @changeGroup="updateGroup"
        @deleteGroup="deleteGroup"
      >
        <template #tableActions="{ item }">
          <li @click="editHandler(item.id)">Редактировать</li>
          <li
            v-if="item.status.value === 'active'"
            @click="blockHandler(item.id, true)"
          >
            Заблокировать
          </li>
          <li v-else @click="blockHandler(item.id, false)">Разблокировать</li>
          <li @click="showPasswordHandler(item.id)">Изменить пароль</li>
        </template>
      </GroupView>
    </div>
  </div>

  <ChangePassword
    v-if="isShowPasswordModal"
    @close="isShowPasswordModal = false"
    @submit="changePasswordHandler"
    password=""
  />

  <ViewAccount v-if="accountsStore.selectedAccount" />

  <DeleteModal
    v-if="accountsStore.isShowDeleteModal"
    title="Удалить учетную запись?"
    @close="accountsStore.setDeleteModal(false)"
    @delete="deleteAccountHandler"
  />
</template>

<script setup>
import Table from "@/components/table/Table";
import Loading from "@/components/Loading";
import { onMounted, ref } from "vue";
import SearchInput from "@/components/table/SearchInput";
import columns from "./model/consts/columns";
import { useAccountsStore } from "./model/store/useAccountsStore";
import GroupsList from "@/components/ui/Groups/GroupsList.vue";
import GroupView from "@/components/ui/Groups/GroupView.vue";
import { deleteAccount } from "./model/api/deleteAccount";
import { blockAccount, unblockAccount } from "./model/api/blockAccount";
import { getAccount } from "./model/api/getAccount";
import ChangePassword from "./ui/components/ChangePassword.vue";
import { changePassword } from "./model/api/changePassword";
import AddAccount from "./ui/modals/AddAccount.vue";
import ViewAccount from "./ui/modals/EditAccount.vue";
import DeleteModal from "@/components/modals/DeleteModal.vue";

const loading = ref(true);
const accountsStore = useAccountsStore();
const accounts = ref([]);
const localId = ref();

const isShowGroupsWindow = ref(false);
const isShowPasswordModal = ref(false);
const groupList = ref([]);
const selectedAccounts = ref([]);
const currentGroup = ref(null);

const editHandler = (id) => {
  accountsStore.setEditMode(true);
  accountsStore.setSelectedAccount(id);
};

const filterAccountsByGroup = (group) => {
  currentGroup.value = group;
  isShowGroupsWindow.value = false;
};

const updateGroups = (newGroups) => {
  groupList.value = newGroups;
  selectedAccounts.value = [];
};

const deleteAccountHandler = async (id) => {
  if (accountsStore.selectedAccount) {
    await deleteAccount(accountsStore.selectedAccount);
  } else {
    await deleteAccount(id);
  }
  accountsStore.setSelectedAccount(null);
  accountsStore.setDeleteModal(false);
  window.dispatchEvent(new CustomEvent("updateTable"));
};

const blockHandler = async (id, block) => {
  if (block) {
    await blockAccount(id);
  } else {
    await unblockAccount(id);
  }
  await getDataHandler();
};

const getDataHandler = async () => {
  accounts.value = await getAccount();
  loading.value = false;
};

const showPasswordHandler = (id) => {
  isShowPasswordModal.value = true;
  localId.value = id;
};

const changePasswordHandler = (password) => {
  changePassword(localId.value, password);
  isShowPasswordModal.value = false;
};

const updateGroup = (items, id) => {
  const group = groupList.value.find((group) => group.id === id);
  if (group) {
    group.items = items;
  }
  if (currentGroup.value) {
    currentGroup.value.items = items;
  }
};

const deleteGroup = (id) => {
  const index = groupList.value.findIndex((item) => item.id === id);
  if (index !== -1) {
    groupList.value.splice(index, 1);
  }
  if (currentGroup.value.id === id) {
    currentGroup.value = null;
  }
};

const selectAccountHandler = (event) => {
  accountsStore.setSelectedAccount(event.data.id);
};

onMounted(() => {
  window.addEventListener("updateTable", getDataHandler);
  loading.value = false;
  getDataHandler();
});
</script>
