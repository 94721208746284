import { DefaultAPIInstance } from "@/api";

export const EmployeesAPI = {
  add(payload) {
    return DefaultAPIInstance.post("/employees", payload);
  },
  getAll() {
    return DefaultAPIInstance.get("/employees");
  },
  getById(id) {
    return DefaultAPIInstance.get(`/employees/${id}`);
  },
  update(payload, id) {
    return DefaultAPIInstance.patch(`/employees/${id}`, payload);
  },
  dismiss(id) {
    return DefaultAPIInstance.patch(`/employees/${id}/dismissal`);
  },
  hire(id) {
    return DefaultAPIInstance.patch(`/employees/${id}/hire`);
  },
  revocateRole(id) {
    return DefaultAPIInstance.delete(`/employees/revocation-role/${id}`);
  },
  reviewRole(id) {
    return DefaultAPIInstance.post(`/employees/review-role/${id}`);
  },
  changeExpiredDate(id, payload) {
    return DefaultAPIInstance.post(
      `/employees/change-expired-date/${id}`,
      payload
    );
  },
  addExternalRole(id, roleId) {
    return DefaultAPIInstance.post(
      `/employees/${id}/add-external-role/${roleId}`
    );
  },
};
