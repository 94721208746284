import { DefaultAPIInstance } from "@/api";

export const ServicesAPI = {
  getAll() {
    return DefaultAPIInstance.get("/services");
  },
  getById(id) {
    return DefaultAPIInstance.get(`/services/${id}`);
  },
};
