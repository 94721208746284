import { defineStore } from "pinia";

export const useAccountsStore = defineStore("accounts", {
  state: () => ({
    isShowDeleteModal: false,
    isEditMode: false,
    isShowFilters: false,
    isShowPasswordModal: false,
    isShowAccountView: false,
    isShowAccountAdd: false,
    selectedAccount: null,
  }),
  actions: {
    setSelectedAccount(payload) {
      this.selectedAccount = payload;
    },
    setAccountAdd(payload) {
      this.isShowAccountAdd = payload;
    },
    setAccountView(payload) {
      this.isShowAccountView = payload;
    },
    setDeleteModal(payload) {
      this.isShowDeleteModal = payload;
    },
    setEditMode(payload) {
      this.isEditMode = payload;
    },
    toggleEditMode() {
      this.isEditMode = !this.isEditMode;
    },
    setShowFilters(payload) {
      this.isShowFilters = payload;
    },
    setPasswordModal(payload) {
      this.isShowPasswordModal = payload;
    },
  },
});
