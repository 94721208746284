export const activeRolesColumn = {
  id: { value: "" },
  name: { value: "Название роли", sortable: true },
  authorOfAssignment: { value: "Автор назначения ", sortable: true },
  expiredDate: { value: "Срок действия", sortable: true },
  assignmentDate: { value: "Дата назначения", sortable: true },
};

export const processingRolesColumn = {
  id: { value: "" },
  name: { value: "Название роли", sortable: true },
  owner: { value: "Автор назначения ", sortable: true },
  status: { value: "Статус", sortable: true },
  createAt: { value: "Дата назначения", sortable: true },
};

export const archiveRolesColumn = {
  id: { value: "" },
  name: { value: "Название роли", sortable: true },
  owner: { value: "Владелец роли", sortable: true },
  revocationDate: { value: "Дата назначения", sortable: true },
  revocationAuthor: { value: "Автор назначения ", sortable: true },
  assignmentDate: { value: "Дата отзыва", sortable: true },
};

export const employeesColumns = {
  id: { value: "" },
  surname: { value: "Фамилия ", sortable: true },
  firstName: { value: "Имя ", sortable: true },
  secondName: { value: "Отчество ", sortable: true },
  departmentName: { value: "Подразделение", sortable: true },
  positionName: { value: "Должность", sortable: true },
};
