<template>
  <!-- Компонент Flex используется с направлением 'column' и пропсом align по умолчанию 'start' -->
  <Flex direction="column" :align="props.align || 'start'" v-bind="flexProps">
    <slot />
  </Flex>
</template>

<script setup>
import Flex from "./Flex.vue";

// Определяем пропсы компонента, за исключением 'direction'
const props = defineProps({
  justify: String,
  align: {
    type: String,
    default: "start",
  },
  wrap: String,
  gap: String,
  max: Boolean,
  className: String,
});

// Передаем все пропсы Flex, кроме 'direction'
const direction = "column"; // Устанавливаем 'column' для вертикального стека

// Прокидываем пропсы в Flex, добавляя 'direction'
const flexProps = {
  ...props,
  direction,
};
</script>
