import { AccountsAPI } from "@/api/AccountsAPI";
import { USER_STATUSES } from "@/enums/enum-status";
import { formatDate } from "@/helpers/formatDate";

export const getAccount = async () => {
  const res = await AccountsAPI.getAll();
  return res.data.map((item) => ({
    ...item,
    createdAt: formatDate(item.createdAt),
    lastLoginAt: formatDate(item.lastLoginAt),
    updateAccountDate: formatDate(item.updateAccountDate),
    service: item.service?.name || "",
    fio: item.employee.fio,
    status: item.active ? USER_STATUSES["active"] : USER_STATUSES["inactive"],
  }));
};
