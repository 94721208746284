<template>
  <Button
    icon="pi-plus"
    @click="accountsStore.setAccountAdd(true)"
    :style="{
      width: '270px',
    }"
    >Добавить учетную запись
  </Button>
  <SideMenu
    width="804"
    v-if="accountsStore.isShowAccountAdd"
    title="Добавить учетную запись"
    @close="accountsStore.setAccountAdd(false)"
  >
    <template #body>
      <AccountForm ref="formRef" />
    </template>
    <template #footer>
      <Button
        variant="solid"
        color="secondary"
        :style="{
          width: '160px',
        }"
        @click="accountsStore.setAccountAdd(false)"
      >
        Отмена
      </Button>
      <Button
        @click="addAccount"
        :style="{
          width: '206px',
        }"
      >
        Добавить
      </Button>
    </template>
  </SideMenu>
</template>

<script setup>
import SideMenu from "@/components/ui/SideMenu/SideMenu.vue";
import { useAccountsStore } from "../../model/store/useAccountsStore";
import Button from "@/components/ui/Button/Button.vue";
import AccountForm from "../form/AccountForm.vue";
import { ref } from "vue";
import { AccountsAPI } from "@/api/AccountsAPI";
import { notify } from "@kyvg/vue3-notification";
import notifications from "@/helpers/notifications";

const accountsStore = useAccountsStore();
const formRef = ref(null);

const addAccount = () => {
  if (!formRef.value) {
    return;
  }
  const formData = formRef.value.getFormValues();
  if (!formData) {
    return;
  }
  const adaptedData = {
    service_id: +formData.serviceId,
    employee_id: +formData.employeeId,
    externalId: "d2a505f0-aff1-40b4-b5cb-4f1aa83803e9",
    login: formData.name,
    password: formData.password,
    updateAccountDate: "1990-01-01",
    lastLoginAt: "2023-08-23T14:34:10Z",
    updatePeriod: formData.renewalPeriod,
    active: formData.active,
    externalRoles: formData.roles,
  };
  if (formData.activeFrom && formData.activeTo) {
    adaptedData.activeFromDate = formData.activeFrom;
    adaptedData.activeToDate = formData.activeTo;
  }
  AccountsAPI.add(adaptedData)
    .then(() => {
      notify({
        type: "success",
        text: "Учетная запись успешно добавлена",
      });
      accountsStore.setAccountAdd(false);
      window.dispatchEvent(new CustomEvent("updateTable"));
    })
    .catch((err) => {
      notifications(err);
    });
};
</script>
