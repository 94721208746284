<template>
  <Teleport to="body">
    <div class="overlay" @click="close">
      <div
        class="content"
        @click.stop
        :style="{
          width: `${width}px`,
        }"
      >
        <header class="header">
          <svg viewBox="0 0 16 16" class="v-icon cancel" @click="close">
            <use href="#cancel" />
          </svg>
          <div class="title">
            <slot name="title">
              <h3>{{ title }}</h3>
            </slot>
          </div>
          <div class="subtitle">
            <slot name="subtitle" />
          </div>
        </header>
        <div class="body">
          <slot name="body"></slot>
        </div>
        <div class="footer">
          <slot name="footer"></slot>
        </div>
      </div>
    </div>
  </Teleport>
</template>

<script setup>
defineProps({
  title: String,
  width: Number,
});

const emit = defineEmits(["close"]);

const close = () => {
  emit("close");
};
</script>

<style scoped lang="scss">
@import "@/assets/scss/_vars";

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  flex-direction: column;
}

.content {
  position: relative;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 100px);
  background: #f9fbff;
  box-shadow: -2px 0px 32px 0px #0000000f;
  border-top-left-radius: 24px;
  border-bottom-left-radius: 24px;
}

.header {
  position: relative;
  padding: 0 32px;
  font-size: 28px;
  margin-bottom: 32px;

  .title {
    margin-top: 52px;

    & + div {
      margin-top: 16px;
    }
  }

  .cancel {
    position: absolute;
    top: 16px;
    right: 32px;
    width: 36px;
    height: 36px;
    padding: 10px;
    background-color: #fff;
    border-radius: 9px;
    cursor: pointer;
  }
}

.body {
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 0 32px 32px;

  &::-webkit-scrollbar {
    width: 2px;
  }
  &::-webkit-scrollbar-track {
    background: $theme200;
  }
  &::-webkit-scrollbar-thumb {
    background: $primary;
  }
}

.footer {
  padding: 32px;
  display: flex;
  justify-content: flex-end;
  gap: 24px;
  box-shadow: 0px -2px 16px 0px #0000000a;

  > button:first-child {
    padding: 0;
  }
}
</style>
