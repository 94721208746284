<template>
  <Card>
    <template #title>
      <h3 class="title">Активные роли</h3>
      <Button
        variant="outlined"
        color="default"
        icon="pi-history"
        @click="updateHandler"
        :style="{
          width: '138px',
        }"
        >Обновить</Button
      >

      <AddRole />
    </template>
    <template #content>
      <Table :columns="activeRolesColumn" :data="activeRoles" :loading="false">
        <template #dropdownActions="{ item }">
          <li @click="revocateRoleHandler(item.id)">Отозвать</li>
          <li @click="reviewRoleHandler(item.id)">Пересмотреть</li>
          <li @click="changeDateModalHandler(item.id)">
            Изменить срок действия
          </li>
        </template>
      </Table>
    </template>
  </Card>
  <Card>
    <template #title>
      <h3 class="title">Роли в обработке</h3>
    </template>
    <template #content>
      <Table
        :columns="processingRolesColumn"
        :data="processingRoles"
        :loading="loading"
      />
    </template>
  </Card>
  <Card>
    <template #title>
      <h3 class="title">Архив ролей</h3>
    </template>
    <template #content>
      <Table
        :columns="archiveRolesColumn"
        :data="archiveRoles"
        :loading="loading"
      />
    </template>
  </Card>

  <ChangeDate
    :id="currentId"
    @close="employeesStore.setChangeDateModal(false)"
  />
</template>

<script setup>
import Table from "@/components/table/Table.vue";
import { ref } from "vue";
import { useEmployeesStore } from "../../model/store/useEmployeesStore";
import ChangeDate from "../components/ChangeDate.vue";
import {
  activeRolesColumn,
  archiveRolesColumn,
  processingRolesColumn,
} from "../../model/consts/columns";
import { EmployeesAPI } from "@/api/EmployeesAPI";
import { notify } from "@kyvg/vue3-notification";
import notifications from "@/helpers/notifications";
import Card from "primevue/card";
import Button from "@/components/ui/Button/Button.vue";
import AddRole from "../components/AddRole.vue";

const employeesStore = useEmployeesStore();
const currentId = ref();

const changeDateModalHandler = (id) => {
  currentId.value = id;
  employeesStore.setChangeDateModal(true);
};

const revocateRoleHandler = (id) => {
  EmployeesAPI.revocateRole(id)
    .then(() => {
      notify({
        type: "success",
        text: "Учетная запись успешно отозвана",
      });
      window.dispatchEvent(new CustomEvent("updateView"));
    })
    .catch((err) => {
      notifications(err);
    });
};

const reviewRoleHandler = (id) => {
  EmployeesAPI.reviewRole(id)
    .then(() => {
      notify({
        type: "success",
        text: "Учетная запись отправлена на пересмотр",
      });
      window.dispatchEvent(new CustomEvent("updateView"));
    })
    .catch((err) => {
      notifications(err);
    });
};

const updateHandler = () => {
  window.dispatchEvent(new CustomEvent("updateView"));
};

defineProps({
  activeRoles: Array,
  processingRoles: Array,
  archiveRoles: Array,
});
</script>
