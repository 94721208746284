import { defineStore } from "pinia";

export const useEmployeesStore = defineStore("employees", {
  state: () => ({
    isShowDeleteModal: false,
    isEditMode: false,
    isShowAddModal: false,
    isShowFilters: false,
    isShowFiredModal: false,
    isShowEditMenu: false,
    isShowAddRolesForm: false,
    isShowAddAccountForm: false,
    isShowChangeDateModal: false,
    isShowActivateAccount: null,
    isShowChangePswdModal: false,
  }),
  actions: {
    setEditMenu(payload) {
      this.isShowEditMenu = payload;
    },
    setShowPswdModal(payload) {
      this.isShowChangePswdModal = payload;
    },
    setChangeDateModal(payload) {
      this.isShowChangeDateModal = payload;
    },
    setShowActivateAccount(payload) {
      this.isShowActivateAccount = payload;
    },
    setShowAddRolesModal(payload) {
      this.isShowAddRolesForm = payload;
    },
    setShowAddAccountForm(payload) {
      this.isShowAddAccountForm = payload;
    },
    setDeleteModal(payload) {
      this.isShowDeleteModal = payload;
    },
    setEditMode(payload) {
      this.isEditMode = payload;
    },
    toggleEditMode() {
      this.isEditMode = !this.isEditMode;
    },
    setAddModal(payload) {
      this.isShowAddModal = payload;
    },
    showFilters() {
      this.isShowFilters = true;
    },
    hideFilters() {
      this.isShowFilters = false;
    },
    showFiredModal() {
      this.isShowFiredModal = true;
    },
    hideFiredModal() {
      this.isShowFiredModal = false;
    },
  },
});
