<template>
  <SideMenu width="804" @close="accountsStore.setSelectedAccount(null)">
    <template #title>
      <HStack gap="8">
        <img
          v-if="account?.synchronized !== 'done'"
          src="../../model/assets/no-sync.svg"
          alt="No Sync"
        />
        <h3>{{ account?.name }}</h3>
      </HStack>
    </template>
    <template #subtitle>
      <Status
        :status="USER_STATUSES[account?.active ? 'active' : 'inactive']"
      />
    </template>
    <template #body>
      <AccountView
        v-if="account?.id"
        ref="formRef"
        :account="account"
        :editMode="accountsStore.isEditMode"
      />
    </template>
    <template #footer>
      <Button
        variant="solid"
        color="secondary"
        :style="{
          width: '160px',
        }"
        @click="accountsStore.setSelectedAccount(null)"
      >
        Отмена
      </Button>
      <Button
        @click="editAccount"
        :style="{
          width: '206px',
        }"
      >
        Добавить
      </Button>
    </template>
  </SideMenu>
</template>

<script setup>
import { AccountsAPI } from "@/api/AccountsAPI";
import Status from "@/components/Status.vue";
import SideMenu from "@/components/ui/SideMenu/SideMenu.vue";
import HStack from "@/components/ui/Stack/HStack.vue";
import { USER_STATUSES } from "@/enums/enum-status";
import { formatDate } from "@/helpers/formatDate";
import { formatPeriod } from "@/helpers/formatPeriod";
import { onMounted, onUnmounted, ref } from "vue";
import { useAccountsStore } from "../../model/store/useAccountsStore";
import AccountView from "../form/AccountView.vue";
import Button from "@/components/ui/Button/Button.vue";
import { notify } from "@kyvg/vue3-notification";
import notifications from "@/helpers/notifications";

const formRef = ref(null);
const account = ref(null);
const accountsStore = useAccountsStore();

const getAccount = () => {
  AccountsAPI.getById(accountsStore.selectedAccount).then((res) => {
    const response = res.data;
    const adaptedData = {
      id: response.id,
      name: response.login || "-",
      serviceId: response.service.id || null,
      serviceName: response.service.name || "-",
      renewalPeriod: formatPeriod(response.updatePeriod) || "-",
      employeeName: response.employee.fio || "-",
      employeeId: response.employee.id || null,
      active: response.active || false,
      roles: response.externalRoles || [],
      activeFrom: response.activeFromDate || null,
      activeTo: response.activeToDate || null,
      temporary: response.temporary || false,
      synchronized: response.synchronized || "-",
      createdAt: formatDate(response.createdAt) || "-",
      lastLoginAt: formatDate(response.lastLoginAt) || "-",
      lastUpdateAt: "-",
      password: response.password || "",
    };
    account.value = adaptedData;
  });
};

const editAccount = () => {
  if (!formRef.value) {
    return;
  }
  const formData = formRef.value.getFormValues();
  if (!formData) {
    return;
  }
  const adaptedData = {
    service_id: +formData.serviceId,
    employee_id: +formData.employeeId,
    login: formData.name,
    updatePeriod: formData.renewalPeriod,
    activeFromDate: formData.activeFrom,
    activeToDate: formData.activeTo,
    externalRoles: formData.roles.map((role) => role.id),
  };
  if (formData.activeFrom && formData.activeTo) {
    adaptedData.activeFromDate = formData.activeFrom;
    adaptedData.activeToDate = formData.activeTo;
  }
  AccountsAPI.update(formData.id, adaptedData)
    .then(() => {
      notify({
        type: "success",
        text: "Учетная запись успешно обновлена",
      });

      accountsStore.setSelectedAccount(null);
    })
    .catch((err) => {
      notifications(err);
    });
  if (formData.active) {
    AccountsAPI.unblock(formData.id);
  } else {
    AccountsAPI.block(formData.id);
  }
  if (formData.password) {
    AccountsAPI.changePassword(formData.id, formData.password);
  }
};

onMounted(() => {
  getAccount();
  window.addEventListener("updateAccount", getAccount);
});

onUnmounted(() => {
  accountsStore.setSelectedAccount(null);
  window.removeEventListener("updateAccount", getAccount);
});
</script>
