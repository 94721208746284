<template>
  <transition name="fade">
    <div v-if="show" class="loading">
      <svg
        width="48"
        height="48"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M8 14.5C11.5899 14.5 14.5 11.5899 14.5 8C14.5 4.41015 11.5899 1.5 8 1.5C4.41015 1.5 1.5 4.41015 1.5 8C1.5 11.5899 4.41015 14.5 8 14.5ZM8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16Z"
          fill="#CDEDF1"
        />
        <mask id="path-2-inside-1_424_21545" fill="white">
          <path
            d="M16 8C16 6.94942 15.7931 5.90914 15.391 4.93853C14.989 3.96793 14.3997 3.08601 13.6569 2.34315C12.914 1.60028 12.0321 1.011 11.0615 0.608964C10.0909 0.206926 9.05058 -4.59221e-08 8 0L8 1.49516C8.85423 1.49516 9.70009 1.66341 10.4893 1.99031C11.2785 2.31721 11.9956 2.79636 12.5996 3.40039C13.2036 4.00442 13.6828 4.7215 14.0097 5.51071C14.3366 6.29991 14.5048 7.14577 14.5048 8H16Z"
          />
        </mask>
        <path
          d="M16 8V9.5H17.5V8H16ZM8 0L8 -1.5L6.5 -1.5L6.5 6.55673e-08L8 0ZM8 1.49516L6.5 1.49516V2.99516H8L8 1.49516ZM14.5048 8H13.0048V9.5H14.5048V8ZM17.5 8C17.5 6.75244 17.2543 5.5171 16.7769 4.36451L14.0052 5.51256C14.3319 6.30117 14.5 7.14641 14.5 8H17.5ZM16.7769 4.36451C16.2994 3.21191 15.5997 2.16464 14.7175 1.28249L12.5962 3.40381C13.1998 4.00739 13.6786 4.72394 14.0052 5.51256L16.7769 4.36451ZM14.7175 1.28249C13.8354 0.400329 12.7881 -0.299436 11.6355 -0.776856L10.4874 1.99478C11.2761 2.32144 11.9926 2.80023 12.5962 3.40381L14.7175 1.28249ZM11.6355 -0.776856C10.4829 -1.25428 9.24756 -1.5 8 -1.5L8 1.5C8.85359 1.5 9.69883 1.66813 10.4874 1.99478L11.6355 -0.776856ZM6.5 6.55673e-08L6.5 1.49516L9.5 1.49516L9.5 -6.55673e-08L6.5 6.55673e-08ZM8 2.99516C8.65724 2.99516 9.30805 3.12462 9.91527 3.37613L11.0633 0.604494C10.0921 0.202214 9.05121 -0.00483756 8 -0.00483751L8 2.99516ZM9.91527 3.37613C10.5225 3.62765 11.0742 3.9963 11.539 4.46105L13.6603 2.33973C12.917 1.59641 12.0345 1.00678 11.0633 0.604494L9.91527 3.37613ZM11.539 4.46105C12.0037 4.92579 12.3724 5.47752 12.6239 6.08473L15.3955 4.93668C14.9932 3.96549 14.4036 3.08304 13.6603 2.33973L11.539 4.46105ZM12.6239 6.08473C12.8754 6.69195 13.0048 7.34276 13.0048 8H16.0048C16.0048 6.94879 15.7978 5.90787 15.3955 4.93668L12.6239 6.08473ZM14.5048 9.5H16V6.5H14.5048V9.5Z"
          fill="#5BC4D1"
          mask="url(#path-2-inside-1_424_21545)"
        />
      </svg>
    </div>
  </transition>
</template>

<script setup>
import { watch } from "vue";

const props = defineProps({
  show: {
    type: Boolean,
    required: true,
  },
});

watch(
  () => props.show,
  (newValue) => {
    // Добавляем или удаляем класс lock в зависимости от значения isLoading
    if (newValue) {
      document.body.classList.add("lock");
    } else {
      document.body.classList.remove("lock");
    }
  },
  { immediate: true } // Чтобы сразу применить класс, если isLoading true при монтировании компонента
);
</script>

<style scoped lang="scss">
@import "@/assets/scss/_vars";
.loading {
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  background-color: $theme100;
  width: 100%;
  top: 100px;
  left: 0;
  right: 0;
  bottom: 0;
}
svg {
  animation-name: rotation;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  display: flex;
  margin: auto;
}
@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
