<template>
  <div class="table-header">
    <SearchInput placeholder="Поиск по Названию или Владельцу..." />
    <div class="button-container">
      <GroupsList
        v-model:showGroups="isShowGroupsWindow"
        :groups="groupList"
        :all-items="roles"
        @update:groups="updateGroups"
        :selectedItems="selectedRoles"
        @clickGroup="filterRolesByGroup"
        @changeGroup="updateGroup"
        @deleteGroup="deleteGroup"
      />
      <RolesFilters :roles="roles" :updateFilteredItems="setFilteredItems" />
    </div>
  </div>
  <Table
    v-if="!currentGroup"
    v-model="selectedRoles"
    name="RolesList"
    :columns="columns"
    :data="filteredItems"
    :loading="loading"
    isSelectable
    :showCheckbox="true"
  >
    <template #actions="{ item }">
      <Button
        icon="pi-pencil"
        color="secondary"
        variant="outlined"
        rounded="sm"
        size="sm"
        @click="editHandler(item.id, item.name)"
      />
      <Button
        icon="pi-trash"
        color="danger"
        variant="outlined"
        rounded="sm"
        size="sm"
        @click="remove(roles, item.id, 'roles', '/roles')"
      />
    </template>
  </Table>
  <GroupView
    v-else
    :group="currentGroup"
    :allItems="roles"
    :columns="columns"
    @close-group="currentGroup = null"
    @changeGroup="updateGroup"
    @deleteGroup="deleteGroup"
  />
</template>

<script setup>
import Table from "@/components/table/Table";
import { ref, onMounted } from "vue";
import { remove } from "@/composables/actions";
import SearchInput from "@/components/table/SearchInput";
import { columns } from "../../../model/consts/columns";
import Button from "@/components/ui/Button/Button.vue";
import RolesFilters from "../../components/RolesFilters.vue";
import { useRolesStore } from "../../../model/store/useRolesStore";
import { useRouter } from "vue-router";
import { RolesAPI } from "@/api/RolesAPI";
import { ServicesAPI } from "@/api/ServicesAPI";
import GroupsList from "@/components/ui/Groups/GroupsList.vue";
import GroupView from "@/components/ui/Groups/GroupView.vue";

const rolesStore = useRolesStore();
const router = useRouter();
const roles = ref([]);
const services = ref([]);

const isShowGroupsWindow = ref(false);
const groupList = ref([]);
const selectedRoles = ref([]);
const currentGroup = ref(null);

const filterRolesByGroup = (group) => {
  currentGroup.value = group;
  isShowGroupsWindow.value = false;
};

const updateGroups = (newGroups) => {
  groupList.value = newGroups;
  selectedRoles.value = [];
};

const updateGroup = (items, id) => {
  const group = groupList.value.find((group) => group.id === id);
  if (group) {
    group.items = items;
  }
  if (currentGroup.value) {
    currentGroup.value.items = items;
  }
};

const deleteGroup = (id) => {
  const index = groupList.value.findIndex((item) => item.id === id);
  if (index !== -1) {
    groupList.value.splice(index, 1);
  }
  if (currentGroup.value.id === id) {
    currentGroup.value = null;
  }
};

const editHandler = (id, name) => {
  rolesStore.setEditMode(true);
  router.push(`/roles/${id}?name=${name}`);
};

const filteredItems = ref(roles);

const setFilteredItems = (items) => {
  filteredItems.value = items;
};

const getRoles = async () => {
  rolesStore.setLoading(true);

  await getServices();

  const res = await RolesAPI.getAll();

  roles.value = res.data.map((item) => {
    const service = services.value.find(
      (service) => service.id === item.serviceId
    );
    return {
      ...item,
      source: service ? service.key : "Не определен",
      owner: item.owner.fio,
    };
  });
};

const getServices = async () => {
  const res = await ServicesAPI.getAll();
  services.value = res.data.map((item) => ({
    id: item.id,
    key: item.name,
  }));
};

onMounted(() => {
  getRoles();
  getServices();
});
</script>
