<template>
  <Dialog v-model:visible="localVisible" modal :header="null">
    <template #header>
      <h2>{{ title }}</h2>
      <p v-if="subtitle">{{ subtitle }}</p>
    </template>
    <slot />
    <template #footer>
      <Button
        color="secondary"
        @click="handleCancel"
        :style="{ width: '160px' }"
      >
        {{ cancelLabel }}
      </Button>
      <Button color="primary" @click="handleSave" :style="{ width: '206px' }">
        {{ saveLabel }}
      </Button>
    </template>
  </Dialog>
</template>

<script setup>
import { ref, watch } from "vue";
import Dialog from "primevue/dialog";
import Button from "../ui/Button/Button.vue";

// Props
const props = defineProps({
  visible: Boolean, // Управление видимостью диалога
  title: {
    type: String,
    default: "Заголовок",
  },
  subtitle: {
    type: String,
    default: "",
  },
  cancelLabel: {
    type: String,
    default: "Отмена",
  },
  saveLabel: {
    type: String,
    default: "Сохранить",
  },
  onCancel: {
    type: Function,
    default: () => {},
  },
  onSave: {
    type: Function,
    default: () => {},
  },
});

// Emits
const emit = defineEmits(["update:visible"]);

const localVisible = ref(props.visible);

// Синхронизация пропса и локального состояния
watch(
  () => props.visible,
  (newVal) => {
    localVisible.value = newVal;
  }
);

watch(
  () => localVisible.value,
  (newVal) => {
    emit("update:visible", newVal);
  }
);

// Обработчики кнопок
const handleCancel = () => {
  localVisible.value = false; // Закрыть диалог
  props.onCancel(); // Вызов пользовательской функции
};

const handleSave = () => {
  localVisible.value = false; // Закрыть диалог
  props.onSave(); // Вызов пользовательской функции
};
</script>
