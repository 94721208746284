<template>
  <Button
    icon="pi-plus"
    @click="employeesStore.setShowAddAccountForm(true)"
    :style="{
      width: '262px',
    }"
    >Добавить учетную запись</Button
  >

  <SideMenu
    @close="closeForm"
    v-if="employeesStore.isShowAddAccountForm"
    title="Добавить учетную запись"
    width="804"
  >
    <template #body>
      <AccountForm />
    </template>
    <template #footer>
      <Button
        variant="solid"
        color="secondary"
        @click="closeForm"
        :style="{
          width: '170px',
        }"
      >
        Отмена
      </Button>
      <Button
        @click="addAccount"
        :style="{
          width: '206px',
        }"
      >
        Добавить
      </Button>
    </template>
  </SideMenu>
</template>

<script setup>
import SideMenu from "@/components/ui/SideMenu/SideMenu.vue";
import { useEmployeesStore } from "../../model/store/useEmployeesStore.js";
import Button from "@/components/ui/Button/Button.vue";
import { notify } from "@kyvg/vue3-notification";
import AccountForm from "@/views/Accounts/ui/form/AccountForm.vue";
import { useAccountsStore } from "@/views/Accounts/model/store/useAccountsStore.js";

const employeesStore = useEmployeesStore();
const accountsStore = useAccountsStore();

const closeForm = () => {
  accountsStore.resetAccount();
  employeesStore.setShowAddAccountForm(false);
};

const addAccount = () => {
  if (!accountsStore.validateForm()) {
    return;
  }
  notify({
    type: "success",
    text: "Аккаунт успешно добавлен",
  });
  closeForm();
  window.dispatchEvent(new CustomEvent("updateTable"));
};
</script>
