import { AccountsAPI } from "@/api/AccountsAPI";
import notifications from "@/helpers/notifications";
import { notify } from "@kyvg/vue3-notification";

export const syncAccountById = (id) => {
  AccountsAPI.syncById(id)
    .then((res) => {
      notify({
        type: res.data.status,
        text: res.data.message,
      });
    })
    .catch((err) => {
      notifications(err);
    });
};
